import React, { useState, useEffect } from 'react';
import { Form, InputNumber, Button, Row, Col } from 'antd';

interface PauseFormProps {
    initialValues?: any,
    buttonTitle?: string,
    onSimulate: (duration: number) => void;
}

const PauseForm: React.FC<PauseFormProps> = ({ initialValues, buttonTitle, onSimulate }) => {
    const [form] = Form.useForm();
    const [duration, setDuration] = useState<number>(initialValues?.duration ?? 1);

    useEffect(() => {
        if (initialValues) {
            setDuration(initialValues.duration);
        }
    }, [initialValues]);

    const handleSimulate = () => {
        onSimulate(duration);
    };

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginInline: 50 }}>
            <Form
                form={form}
                layout="vertical"
                initialValues={{ duration }}
                style={{ width: "100%", maxWidth: "600px", marginInline: 50 }}
            >
                <Row gutter={[16, 16]} wrap={true} style={{ width: '100%' }}>
                    <Col xs={24} sm={12} md={12}>
                        <Form.Item
                            name="duration"
                            label="Pausa (secondi)"
                            rules={[{ required: true, message: 'Inserisci la durata della pausa!' }]}
                            style={{ marginRight: '8px' }}
                        >
                            <InputNumber
                                min={1}
                                value={duration}
                                onChange={(value) => setDuration(value as number)}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Form.Item>
                            <Button type="primary" onClick={handleSimulate} style={{ width: '100%' }}>
                                {buttonTitle ? buttonTitle : "Aggiungi Pausa"}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default PauseForm;
