export enum TestState {
    WAITING,
    RUNNING,
    SUCCESS,
    ERROR,
}
export interface TestChain {
    id: string | null,
    name: string | null,
    tests: TestStep[]
}

export interface TestStep {
    testType: string,
    testParams: any,
    testState: TestState,
    isBlocking: boolean
}