// src/components/MessageManager.tsx

import React, {useEffect, useState} from 'react';
import {
    Form,
    Input,
    Button,
    Select,
    Table,
    message as antdMessage,
    Tooltip,
    Row,
    Col,
    TableColumnsType, Divider
} from 'antd';
import {CreateMessage, Message, MessageType} from '../../types/Message';
import {
    saveMessage,
    getMessages,
    updateMessage,
    deleteMessage,
    simulateNewMessages, simulateNewMessage
} from '../../service/messageService';
import {IoPencilOutline, IoPlayOutline, IoTrashOutline} from "react-icons/io5";
import SimulateMessages from "./SimulateMessages";
import {useUsersContext} from "../contexts/UsersContext";
import {useChatsContext} from "../contexts/ChatsContext";

const {Option} = Select;

const MessageManagement: React.FC = () => {
    const [form] = Form.useForm();
    const [messages, setMessages] = useState<Message[]>([]);
    const [loading, setLoading] = useState(false);
    const [editingMessage, setEditingMessage] = useState<Message | null>(null);
    const {users} = useUsersContext()
    const {chats} = useChatsContext()
    const [selectedUser, setSelectedUser] = useState<string | undefined>(undefined);
    // const [randomUser, setRandomUser] = useState<string>("")
    const fetchMessages = async () => {
        setLoading(true);
        try {
            const data = await getMessages();
            setMessages(data);
        } catch (error) {
            antdMessage.error('Errore caricamento messaggi.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMessages()
            .catch(error =>
                antdMessage.error(`Errore caricamento messaggi: ${error}`));
    }, []);

    const onFinish = async (values: CreateMessage) => {
        try {
            if (editingMessage) {
                await updateMessage(editingMessage.id, values);
                antdMessage.success('Messaggio modificato con successo!');
            } else {
                await saveMessage(values);
                antdMessage.success('Messaggio salvato con successo!');
            }
            form.resetFields();
            fetchMessages()
                .catch(error =>
                    antdMessage.error(`Errore caricamento messaggi: ${error}`));
            setEditingMessage(null);
        } catch (error) {
            antdMessage.error('Errore salvataggio messaggio.');
        }
    };
    const onSend = async (message: Message) => {
        try {
            await simulateNewMessage(message);
            antdMessage.success("Messaggio inviato con successo!")
        } catch (error) {
            antdMessage.error("Errore durante l'invio del messaggio.")
        }
    }
    const onEdit = (message: Message) => {
        form.setFieldsValue(message);
        setEditingMessage(message);
    };
    const onDelete = async (id: string) => {
        try {
            await deleteMessage(id);
            antdMessage.success('Messaggio eliminato con successo!');
            fetchMessages()
                .catch(error =>
                antdMessage.error(`Errore caricamento messaggi: ${error}`));
        } catch (error) {
            antdMessage.error('Errore durante la cancellazione del messaggio.');
        }
    };

    const columns: TableColumnsType<Message> = [
        {
            title: 'Chat ID',
            dataIndex: 'chatId',
            key: 'chatId',
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Inviato da',
            dataIndex: 'sender',
            key: 'sender',
        },
        {
            title: 'Contenuto',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            width: 180,
            render: (_: any, record: Message) => (
                <>
                    <Tooltip title="Modifica messaggio">
                        <Button style={{marginRight: 8}} icon={<IoPencilOutline/>} type="primary"
                                onClick={() => onEdit(record)}></Button>
                    </Tooltip>
                    <Tooltip title="Invia messaggio">
                        <Button style={{marginRight: 8}} icon={<IoPlayOutline/>} type="primary"
                                onClick={() => onSend(record)}></Button>
                    </Tooltip>
                    <Tooltip title="Elimina Messaggio">
                        <Button style={{marginLeft: 20, marginRight: 8}} icon={<IoTrashOutline/>} danger
                                onClick={() => onDelete(record.id!)}></Button>
                    </Tooltip>
                </>
            ),
        },
    ];

    const handleSimulate = async (quantity: number, fromDB: boolean, nPerMinute: number) => {
        setLoading(true);
        try {
            const response = await simulateNewMessages(quantity, fromDB, nPerMinute)

            if (response.status === 200) {
                antdMessage.success("Invio dei messaggi avvenuto con successo")
            } else {
                antdMessage.error("Errore durante l'invio dei messaggi");
            }
        } catch (error) {
            antdMessage.error(`Errore durante l'invio dei messaggi: ${error}`);
        }
        setLoading(false);
    };
    const handleSelectUser = (value: string) => {
        setSelectedUser(value)
        form.resetFields(["chatId"])
    }

    useEffect(() => {
        if (users?.length > 0) {
            const randUser = "1"//users[(Math.floor(Math.random() * users.length))].id
            // setRandomUser(randUser)
            console.log(randUser)
        }
    }, [users]);

    return (
        <>
            <h2 style={{width: "100%", textAlign: "center"}}>Gestione dei messaggi</h2>
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    sender: "1",
                    chatId: "1",
                    content: `contenuto ${(Math.random() * 1000).toFixed(0)}`,
                    responseFor: null,
                    attachment: null,
                    type: MessageType.MESSAGE
                }}
                style={{
                    justifyContent: "center",
                    justifyItems: "center",
                    justifySelf: "center",
                    marginLeft: 50,
                    marginRight: 50
                }}>
                <Row gutter={[40, 0]}>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item
                            name="sender"
                            label="Inviato da"
                            rules={[{required: true, message: 'Inserire chi ha inviato il messaggio!'}]}
                        >
                            <Select placeholder="Seleziona un utente"
                                    onSelect={handleSelectUser}
                                    notFoundContent={"Non ci sono utenti"}>
                                {
                                    users.map(user => (
                                        <Option key={user.id} value={user.id} isSelectOption={user.id === selectedUser}>
                                            {user.id} - {user.name} {user.surname}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item
                            name="chatId"
                            label="Chat ID"
                            rules={[{required: true, message: 'Inserire l\'ID della chat!'}]}
                        >
                            <Select disabled={selectedUser === null || selectedUser === undefined}
                                    placeholder="Seleziona una chat"
                                    notFoundContent={"L'utente selezionato non ha chat"}>
                                {selectedUser && chats[selectedUser]?.map((chatId: string) => (
                                    <Option key={chatId} value={chatId}>
                                        {chatId}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item name="type" label="Tipo di messaggio">
                            <Select>
                                <Option value={MessageType.MESSAGE}>Message</Option>
                                <Option value={MessageType.REMOVE}>REMOVE</Option>
                                <Option value={MessageType.ADD}>ADD</Option>
                                <Option value={MessageType.JOIN}>JOIN</Option>
                                <Option value={MessageType.LEAVE}>LEAVE</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item
                            name="content"
                            label="Contenuto"
                            rules={[{required: true, message: 'Inserire il contenuto!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item name="attachment" label="Allegato">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item name="responseFor" label="Risposta al messaggio">
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {editingMessage ? 'Modifica messaggio' : 'Salva messaggio'}
                    </Button>
                </Form.Item>
            </Form>
            <Divider />
            <SimulateMessages onSimulate={handleSimulate}/>
            <Table
                locale={{emptyText: "Non ci sono messaggi"}}
                columns={columns}
                dataSource={messages}
                rowKey="id"
                loading={loading}/>
        </>
    );
};

export default MessageManagement;
