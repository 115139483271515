import React, {useState} from 'react';
import {AimOutlined, AppstoreOutlined, MailOutlined, MergeOutlined, SettingOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Menu} from 'antd';
import {GiTreasureMap} from "react-icons/gi";
import {IoChatbubbleEllipsesOutline} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {GrConnectivity} from "react-icons/gr";
import {BiTestTube} from "react-icons/bi";
import {LiaMapMarkerSolid} from "react-icons/lia";

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
    {
        label: 'Test',
        key: 'test',
        icon: <BiTestTube/>
    },
    {
        label: 'Chat',
        key: 'chat',
        icon: <IoChatbubbleEllipsesOutline />,
        children: [
            {
                type: 'group',
                label: 'Gestione chat',
                children: [
                    {label: 'Gestisci le chat', key: 'chatManagement'},
                    {label: 'Gestisci i messaggi', key: 'messageManagement'},
                ],
            }
        ],
    },
    {
        label: 'Inseguimento',
        key: 'chase',
        icon: <MergeOutlined />,
        // disabled: true,
    },
    {
        label: 'Dispositivi',
        key: 'devices',
        icon: <AimOutlined/>,
    },
    {
        label: 'Simulatore spostamenti',
        key: 'simulations',
        icon: <GiTreasureMap/>,
        // disabled: true,
    },
    {
        label: "Connessioni STOMP",
        key: 'stompConnection',
        icon: <GrConnectivity/>
    },
    {
        label: "AREE POI",
        key: 'poi',
        icon: <LiaMapMarkerSolid/>
    }
];

interface AdminNavBarProps{
    current: any,
    setCurrent: any
}
const AdminNavBar: React.FC<AdminNavBarProps> = ({current, setCurrent}) => {
    const navigate = useNavigate()

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
        navigate(e.key)
        // console.log(e.key)
    };

    return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
};

export default AdminNavBar;