import React, {useState} from 'react';
import {Form, InputNumber, Radio, Button, Divider, Space, Row, Col} from 'antd';

interface SimulateChasesProps {
    buttonTitle?: string,
    initialValues?: any,
    onSimulate: (quantity: number, fromDB: boolean, nPerMinute: number) => void;
}

const SimulateChases: React.FC<SimulateChasesProps> = ({onSimulate, buttonTitle, initialValues}) => {
    const [quantity, setQuantity] = useState<number>(initialValues?.quantity ?? 10);
    const [nPerMinute, setNPerMinute] = useState<number>(initialValues?.nPerMinute ?? 600);
    const [fromDB, setFromDB] = useState<boolean>(initialValues?.fromDB ?? false);

    const handleSimulate = () => {
        onSimulate(quantity, fromDB, nPerMinute);
    };

    return (
        <>
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                <Form
                    layout="vertical"
                    style={{width: "100%", maxWidth: "1000px", marginInline: 50}}
                    initialValues={{quantity: 10, fromDB: false, nPerMinute: 600}}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label="Numero di inseguimenti">
                                <InputNumber
                                    min={1}
                                    value={quantity}
                                    onChange={(value) => setQuantity(value as number)}
                                    style={{width: '100%'}}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label="Sorgente">
                                <Radio.Group
                                    value={fromDB}
                                    onChange={(e) => setFromDB(e.target.value)}
                                >
                                    <Space direction="vertical">
                                        <Radio value={true}>Dal database</Radio>
                                        <Radio value={false}>Genera casuali</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label="Inseguimenti al minuto">
                                <InputNumber
                                    min={60}
                                    value={nPerMinute}
                                    onChange={(value) => setNPerMinute(value as number)}
                                    style={{width: '100%'}}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Form.Item>
                                <Button type="primary" onClick={handleSimulate} style={{width: '100%'}}>
                                    {buttonTitle ? buttonTitle : "Simula"}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default SimulateChases;
