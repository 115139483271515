import React, {useState, useEffect} from 'react';
import {
    Button,
    Divider,
    Form,
    InputNumber,
    List,
    message as antdMessage,
    Space,
    Tag,
    Tooltip,
    Select,
    Col,
    Row,
    Table,
    TableColumnsType, Input
} from 'antd';
import {simulateNewChats} from '../../service/chatService';
import {simulateNewMessages} from '../../service/messageService';
import {createStompConnections} from '../../service/StompConnectionService';
import SimulateChats from '../chat/SimulateChats';
import SimulateMessages from '../chat/SimulateMessages';
import SimulateStompConnections from '../stompconnection/SimulateStompConnections';
import SimulateChases from '../chase/SimulateChases';
import {IoPencilOutline, IoPlayOutline, IoSaveOutline, IoTrashOutline} from 'react-icons/io5';
import {simulateNewChases} from '../../service/chaseService';
import {
    saveTestChain,
    getAllTestChains,
    getTestChainById,
    updateTestChain,
    deleteTestChain
} from '../../service/TestChainService';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import DraggableTestItem from './DraggableTestItem';
import {TestState, TestStep, TestChain} from '../../types/TestChain';
import {ServerType, StompConnectionRequest} from "../../types/StompConnection";
import PauseForm from "./PauseForm";

const TestManagement: React.FC = () => {
    const [testChain, setTestChain] = useState<TestStep[]>([]);
    const [form] = Form.useForm();
    const [savedChains, setSavedChains] = useState<TestChain[]>([]);
    const [selectedChain, setSelectedChain] = useState<string | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [testChainName, setTestChainName] = useState<string | null>(null);
    const [testChainId, setTestChainId] = useState<string | null>(null);
    const [editingChainName, setEditingChainName] = useState<boolean>(!testChainName);
    useEffect(() => {
        loadSavedChains();
    }, []);

    const loadSavedChains = async () => {
        try {
            const response = await getAllTestChains();
            setSavedChains(response.data);
        } catch (error) {
            antdMessage.error('Errore durante il caricamento delle catene salvate');
        }
    };

    const handleSaveTestChain = async () => {
        const name = (testChainName && testChainName!=="") ? testChainName : prompt('Inserisci un nome per la catena di test:');
        if (!name) return;

        const testChainDTO: TestChain = {
            id: testChainId,
            name: name,
            tests: testChain,
        };

        try {
            if (isEditing) {
                await updateTestChain(testChainDTO);
                antdMessage.success('Catena di test aggiornata con successo');
            } else {
                await saveTestChain(testChainDTO);
                antdMessage.success('Catena di test salvata con successo');
            }
            loadSavedChains();
            setIsEditing(false);
            setSelectedChain(null);
        } catch (error) {
            antdMessage.error('Errore durante il salvataggio della catena di test');
        }
    };

    const handleLoadTestChain = async (id: string) => {
        try {
            const response = await getTestChainById(id);
            const loadedChain: TestChain = response.data;
            const tmp = loadedChain.tests.map(test => {
                return {...test, testState: TestState.WAITING}
            })
            setTestChain(tmp);
            setSelectedChain(id);
            setTestChainId(id);
            setTestChainName(loadedChain.name)
            setIsEditing(true);
        } catch (error) {
            antdMessage.error('Errore durante il caricamento della catena di test');
        }
    };

    const handleDeleteTestChain = async (id: string) => {
        try {
            await deleteTestChain(id);
            antdMessage.success('Catena di test eliminata con successo');
            if (id === testChainId) {
                setTestChainName(null);
                setTestChain([]);
                setTestChainId(null);
            }
            loadSavedChains();
        } catch (error) {
            antdMessage.error('Errore durante l\'eliminazione della catena di test');
        }
    };

    const handleChatsSimulate = async (quantity: number, fromDB: boolean, nPerMinute: number) => {
        try {
            const response = await simulateNewChats(quantity, fromDB, nPerMinute);
            if (response.status === 200) {
                antdMessage.success('Creazione delle chat avvenuta con successo');
            } else {
                antdMessage.error('Errore durante la simulazione di creazione delle chat');
            }
        } catch (error) {
            antdMessage.error(`Errore durante la simulazione di creazione delle chat: ${error}`);
        }
    };

    const handleChasesSimulate = async (quantity: number, fromDB: boolean, nPerMinute: number) => {
        try {
            const response = await simulateNewChases(quantity, fromDB, nPerMinute);
            if (response.status === 200) {
                antdMessage.success('Creazione degli inseguimenti avvenuta con successo');
            } else {
                antdMessage.error('Errore durante la simulazione di creazione degli inseguimenti');
            }
        } catch (error) {
            antdMessage.error(`Errore durante la simulazione di creazione degli inseguimenti: ${error}`);
        }
    };

    const handleMessagesSimulate = async (quantity: number, fromDB: boolean, nPerMinute: number) => {
        try {
            const response = await simulateNewMessages(quantity, fromDB, nPerMinute);
            if (response.status === 200) {
                antdMessage.success('Invio dei messaggi avvenuto con successo');
            } else {
                antdMessage.error('Errore durante l\'invio dei messaggi');
            }
        } catch (error) {
            antdMessage.error(`Errore durante l\'invio dei messaggi: ${error}`);
        }
    };

    const handleStompConnectionsSimulate = async (numberOfConnections: number, serverType: ServerType, durationInSeconds: number) => {
        const request: StompConnectionRequest = {
            numberOfConnections,
            type: serverType,
            durationInSeconds,
        };
        try {
            await createStompConnections(request);
            antdMessage.success('Connessioni create con successo');
        } catch (error) {
            antdMessage.error('Errore durante la creazione delle connessioni');
        }
    };

    const addTestToChain = (testType: string, testParams: any, isBlocking: boolean) => {
        setTestChain([...testChain, {testType, testParams, testState: TestState.WAITING, isBlocking}]);
    };
    const runTest = async (index: number, testParams: any, isBlocking: boolean, handleTestSimulate: Function) => {
        setTestChain(prevChain => {
            const newChain = [...prevChain];
            newChain[index].testState = TestState.RUNNING;
            return newChain;
        });

        const simulateTest = async () => {
            await handleTestSimulate(testParams)
                .then(() => setTestChain(prevChain => {
                    const newChain = [...prevChain];
                    newChain[index].testState = TestState.SUCCESS;
                    return newChain;
                }))
                .catch(() => setTestChain(prevChain => {
                    const newChain = [...prevChain];
                    newChain[index].testState = TestState.ERROR;
                    return newChain;
                }));
        };

        if (isBlocking) {
            await simulateTest();
        } else {
            simulateTest();
        }
    };

    const runTestChain = async () => {

        const tmp = testChain.map(test => {
            return {...test, testState: TestState.WAITING}
        })
        setTestChain(tmp)
        for (let i = 0; i < testChain.length; i++) {
            const {testType, testParams, isBlocking} = testChain[i];
            try {
                switch (testType) {
                    case 'chat':
                        await runTest(i, testParams, isBlocking, (params: { quantity: number; fromDB: boolean; nPerMinute: number; }) => handleChatsSimulate(params.quantity, params.fromDB, params.nPerMinute));
                        break;
                    case 'message':
                        await runTest(i, testParams, isBlocking, (params: { quantity: number; fromDB: boolean; nPerMinute: number; }) => handleMessagesSimulate(params.quantity, params.fromDB, params.nPerMinute));
                        break;
                    case 'stomp':
                        await runTest(i, testParams, isBlocking, (params: { numberOfConnections: number; serverType: ServerType; durationInSeconds: number; }) => handleStompConnectionsSimulate(params.numberOfConnections, params.serverType, params.durationInSeconds));
                        break;
                    case 'chase':
                        await runTest(i, testParams, isBlocking, (params: { quantity: number; fromDB: boolean; nPerMinute: number; }) => handleChasesSimulate(params.quantity, params.fromDB, params.nPerMinute));
                        break;
                    case 'pause':
                        await runTest(i, testParams, isBlocking, (params: { duration: number; }) => new Promise(resolve => setTimeout(resolve, params.duration * 1000)));
                        break;
                    default:
                        break;
                }
                // switch (testType) {
                //     case 'chat':
                //         setTestChain(prevChain => {
                //             const newChain = [...prevChain];
                //             newChain[i].testState = TestState.RUNNING;
                //             return newChain;
                //         });
                //         if(isBlocking){
                //             await handleChatsSimulate(testParams.quantity, testParams.fromDB, testParams.nPerMinute)
                //                 .then(() => setTestChain(prevChain => {
                //                     const newChain = [...prevChain];
                //                     newChain[i].testState = TestState.SUCCESS;
                //                     return newChain;
                //                 }));
                //         } else {
                //             handleChatsSimulate(testParams.quantity, testParams.fromDB, testParams.nPerMinute)
                //                 .then(() => setTestChain(prevChain => {
                //                     const newChain = [...prevChain];
                //                     newChain[i].testState = TestState.SUCCESS;
                //                     return newChain;
                //                 }));
                //         }
                //         break;
                //     case 'message':
                //         setTestChain(prevChain => {
                //             const newChain = [...prevChain];
                //             newChain[i].testState = TestState.RUNNING;
                //             return newChain;
                //         });
                //         handleMessagesSimulate(testParams.quantity, testParams.fromDB, testParams.nPerMinute)
                //             .then(() => setTestChain(prevChain => {
                //                 const newChain = [...prevChain];
                //                 newChain[i].testState = TestState.SUCCESS;
                //                 return newChain;
                //             }));
                //         break;
                //     case 'stomp':
                //         setTestChain(prevChain => {
                //             const newChain = [...prevChain];
                //             newChain[i].testState = TestState.RUNNING;
                //             return newChain;
                //         });
                //         handleStompConnectionsSimulate(testParams.numberOfConnections, testParams.serverType, testParams.durationInSeconds)
                //             .then(() => setTestChain(prevChain => {
                //                 const newChain = [...prevChain];
                //                 newChain[i].testState = TestState.SUCCESS;
                //                 return newChain;
                //             }));
                //         break;
                //     case 'chase':
                //         setTestChain(prevChain => {
                //             const newChain = [...prevChain];
                //             newChain[i].testState = TestState.RUNNING;
                //             return newChain;
                //         });
                //         handleChasesSimulate(testParams.quantity, testParams.fromDB, testParams.nPerMinute)
                //             .then(() => setTestChain(prevChain => {
                //                 const newChain = [...prevChain];
                //                 newChain[i].testState = TestState.SUCCESS;
                //                 return newChain;
                //             }));
                //         break;
                //     case 'pause':
                //         setTestChain(prevChain => {
                //             const newChain = [...prevChain];
                //             newChain[i].testState = TestState.RUNNING;
                //             return newChain;
                //         });
                //         await new Promise(resolve => setTimeout(resolve, testParams.duration * 1000));
                //         setTestChain(prevChain => {
                //             const newChain = [...prevChain];
                //             newChain[i].testState = TestState.SUCCESS;
                //             return newChain;
                //         });
                //         break;
                //     default:
                //         break;
                // }
            } catch (error) {
                setTestChain(prevChain => {
                    const newChain = [...prevChain];
                    newChain[i].testState = TestState.ERROR;
                    return newChain;
                });
            }
        }
    };
    const runSingleTest = async (index: number) => {
        const {testType, testParams} = testChain[index];
        try {
            setTestChain(prevChain => {
                const newChain = [...prevChain];
                newChain[index].testState = TestState.RUNNING;
                return newChain;
            });
            switch (testType) {
                case 'chat':
                    await handleChatsSimulate(testParams.quantity, testParams.fromDB, testParams.nPerMinute);
                    break;
                case 'message':
                    await handleMessagesSimulate(testParams.quantity, testParams.fromDB, testParams.nPerMinute);
                    break;
                case 'stomp':
                    await handleStompConnectionsSimulate(testParams.numberOfConnections, testParams.type, testParams.durationInSeconds);
                    break;
                case 'chase':
                    await handleChasesSimulate(testParams.quantity, testParams.fromDB, testParams.nPerMinute);
                    break;
                case 'pause':
                    await new Promise(resolve => setTimeout(resolve, testParams.duration * 1000));
                    break;
                default:
                    break;
            }
            setTestChain(prevChain => {
                const newChain = [...prevChain];
                newChain[index].testState = TestState.SUCCESS;
                return newChain;
            });
        } catch (error) {
            setTestChain(prevChain => {
                const newChain = [...prevChain];
                newChain[index].testState = TestState.ERROR;
                return newChain;
            });
        }
    };

    const [editingTest, setEditingTest] = useState<{ index: number, test: TestStep } | null>(null);
    const saveTestEdits = (editingTest: { index: number, test: TestStep }) => {
        const {index, test} = editingTest;
        const newChain = [...testChain];
        newChain[index] = test;
        console.log(`New: ${JSON.stringify(newChain)}`)
        setTestChain(newChain);
        setEditingTest(null);
    };

    const onDelete = (index: number, testChain: TestStep[]) => {
        const tmp = [...testChain];
        tmp.splice(index, 1);
        setTestChain(tmp);
    };

    const moveTest = (dragIndex: number, hoverIndex: number) => {
        const draggedTest = testChain[dragIndex];
        setTestChain(update(testChain, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, draggedTest],
            ],
        }));
    };

    const changeBlocking = (index: number) => {
        setTestChain(prevChain => {
            const newChain = [...prevChain];
            newChain[index].isBlocking = !newChain[index].isBlocking;
            return newChain;
        })
    }
    const columns: TableColumnsType<TestChain> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Azioni',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Esegui la catena di test">
                        <Button type="primary" icon={<IoPlayOutline/>} onClick={() => runTestChain()}></Button>
                    </Tooltip>
                    <Tooltip title="Modifica la catena di test">
                        <Button type="default" icon={<IoPencilOutline/>}
                                onClick={() => handleLoadTestChain(record.id!!)}></Button>
                    </Tooltip>
                    <Tooltip title="Elimina la catena di test">
                        <Button type="primary" icon={<IoTrashOutline/>} danger
                                onClick={() => handleDeleteTestChain(record.id!!)}></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const saveChainName = () => {
        // Implementa la logica per salvare il nome della catena
        // Ad esempio, una chiamata API per aggiornare il nome della catena
        setEditingChainName(false);
    };

    return (
        <div>
            <h2 style={{width: "100%", textAlign: "center"}}>Gestione dei test</h2>
            <h5 style={{width: "100%", textAlign: "center"}}>Crea una catena di test</h5>

            <Space direction="vertical" style={{width: '100%'}}>
                <SimulateStompConnections
                    buttonTitle={"Aggiungi alla catena"}
                    onSimulate={(numberOfConnections, serverType, durationInSeconds) => addTestToChain('stomp', {
                        numberOfConnections,
                        serverType,
                        durationInSeconds,
                    }, false)}
                />
                <Divider style={{width: "100%"}}/>
                <SimulateChats
                    buttonTitle={"Aggiungi alla catena"}
                    onSimulate={(quantity, fromDB, nPerMinute) => addTestToChain('chat', {
                        quantity,
                        fromDB,
                        nPerMinute
                    }, false)}
                />
                <Divider style={{width: "100%"}}/>
                <SimulateMessages
                    buttonTitle={"Aggiungi alla catena"}
                    onSimulate={(quantity, fromDB, nPerMinute) => addTestToChain('message', {
                        quantity,
                        fromDB,
                        nPerMinute
                    }, false)}
                />
                <Divider style={{width: "100%"}}/>
                <SimulateChases
                    buttonTitle={"Aggiungi alla catena"}
                    onSimulate={(quantity, fromDB, nPerMinute) => addTestToChain('chase', {
                        quantity,
                        fromDB,
                        nPerMinute
                    }, false)}
                />
                <Divider style={{width: 2}}/>
                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <PauseForm
                        buttonTitle="Aggiungi Pausa"
                        onSimulate={(duration) => addTestToChain('pause', {duration}, true)}
                    />
                </div>
                <Divider style={{width: "100%"}}/>
                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <DndProvider backend={HTML5Backend}>
                        <List
                            locale={{emptyText: "Non ci sono test"}}
                            style={{width: "80%"}}
                            header={
                                <div style={{ display: "flex", alignItems: "center", width: "80%" }}>
                                    {editingChainName ? (
                                        <>
                                            <Input
                                                placeholder="Nome della catena"
                                                value={testChainName ?? ""}
                                                onChange={(e) => setTestChainName(e.target.value)}
                                                style={{ flexGrow: 1 }}
                                            />
                                            <Button
                                                onClick={saveChainName}
                                                type="primary"
                                                style={{ marginLeft: 8 }}
                                            >
                                                Salva nome
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <span style={{ flexGrow: 1 }}>{testChainName}</span>
                                            <Button
                                                onClick={() => setEditingChainName(true)}
                                                style={{ marginLeft: 8 }}
                                            >
                                                Modifica nome
                                            </Button>
                                        </>
                                    )}
                                </div>

                            }
                            bordered
                            dataSource={testChain}
                            renderItem={(item, index) => (
                                <DraggableTestItem
                                    item={item}
                                    index={index}
                                    moveTest={moveTest}
                                    onDelete={onDelete}
                                    testChain={testChain}
                                    onEdit={() => setEditingTest({index, test: item})}
                                    onRun={() => runSingleTest(index)}
                                    editingTest={editingTest}
                                    setEditingTest={setEditingTest}
                                    saveTestEdits={saveTestEdits}
                                    form={form}
                                    changeBlocking={changeBlocking}
                                />)}
                        />
                    </DndProvider>
                </div>

                <div style={{width: "100%", display: "flex", justifyContent: "center", margin: 50}}>
                    <Button disabled={testChain.length === 0} type="primary" onClick={runTestChain}>Esegui Catena di
                        Test</Button>
                    <Button style={{marginLeft: 20}} disabled={testChain.length === 0} type="primary"
                            onClick={handleSaveTestChain} icon={<IoSaveOutline/>}>
                        {isEditing ? "Salva Modifiche" : "Salva Catena di Test"}
                    </Button>
                </div>

                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <Table
                        style={{width: '80%'}}
                        columns={columns}
                        dataSource={savedChains}
                        rowKey="id"
                    />
                </div>
            </Space>
        </div>
    );
};

export default TestManagement;

// TODO sembra non essere concorrente quando lo lancio sulla VPS