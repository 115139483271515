import React, {useState, useCallback} from 'react';
import {GoogleMap, LoadScript, Marker, useJsApiLoader} from '@react-google-maps/api';
import {Button, Modal} from 'antd';
import {google_api_key} from "../consts";

const containerStyle = {
    width: '800px',
    height: '600px'
};

const center = {
    lat: 45.064701,
    lng: 7.673420
};

const MapSelectorSinglePoint: React.FC<{ onSelect: (point: { lat: number, lng: number } | null) => void }> = ({onSelect}) => {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: google_api_key
    });

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [marker, setMarker] = useState<{ lat: number, lng: number } | null>(null);

    const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
        setMarker({lat: event.latLng!.lat(), lng: event.latLng!.lng()});
    }, [marker]);

    const onLoad = useCallback((mapInstance: google.maps.Map) => {
        setMap(mapInstance);
    }, []);

    const handleConfirm = () => {
        onSelect(marker);
    };

    return isLoaded ? (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                onClick={onMapClick}
                onLoad={onLoad}
            >
                {marker && <Marker key={1} position={marker}/>}
            </GoogleMap>
            <Button type="primary" onClick={handleConfirm} disabled={marker===null}>Conferma</Button>
        </>
    ) : <></>;
}

export default MapSelectorSinglePoint
