import axios from 'axios';
import { POI } from '../types/POI';

const API_URL = '/API/pois';

export const getPOIs = async (): Promise<POI[]> => {
    const response = await axios.get<POI[]>(API_URL);
    return response.data;
};

export const savePOI = async (poi: POI): Promise<void> => {
    await axios.post(API_URL, poi);
};

export const updatePOI = async (poi: POI): Promise<void> => {
    await axios.put(`${API_URL}/${poi.rcsEventsId}`, poi);
};

export const deletePOI = async (rcsEventsId: string): Promise<void> => {
    await axios.delete(`${API_URL}/${rcsEventsId}`);
};
