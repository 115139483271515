import React, {useEffect, useState} from 'react';
import {
    Form,
    Input,
    Button,
    Select,
    Table,
    message as antdMessage,
    Tooltip,
    Row,
    Col,
    TableColumnsType, Modal
} from 'antd';
import {Device} from "../../types/Device";
import {deleteDevice, getDevices, saveDevice, updateDevice} from "../../service/DeviceService";
import {IoPencilOutline, IoTrashOutline} from "react-icons/io5";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import MapSelectorSinglePoint from "./MapSelectorSinglePoint";

const {Option} = Select;

const DeviceManagement: React.FC = () => {
    const [form] = Form.useForm();
    const [devices, setDevices] = useState<Device[]>([]);
    const [loading, setLoading] = useState(false);
    const [editingDevice, setEditingDevice] = useState<Device | null>(null);
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [isMapModalVisible, setIsMapModalVisible] = useState<boolean>(false);
    const handleMapSelect = (point: { lat: number, lng: number } | null) => {
        if (point) {
            form.setFieldsValue({
                numericalLatitude: point.lat.toFixed(6),
                numericalLongitude: point.lng.toFixed(6),
            });
            setIsMapModalVisible(false);
        }
    };

    const toggleFields = () => {
        setShowAdditionalFields(!showAdditionalFields);
    };
    const fetchDevices = async () => {
        setLoading(true);
        try {
            const data = await getDevices();
            setDevices(data);
        } catch (error) {
            antdMessage.error('Failed to load devices.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDevices()
            .catch(error =>
                antdMessage.error(`Errore caricamento chat: ${error}`))
    }, []);

    const onFinish = async (values: Device) => {
        const deviceValues = {...values};
        try {
            if (editingDevice) {
                await updateDevice({...deviceValues});
                antdMessage.success('Device updated successfully!');
            } else {
                await saveDevice(deviceValues);
                antdMessage.success('Device saved successfully!');
            }
            form.resetFields();
            fetchDevices()
                .catch(error =>
                    antdMessage.error(`Errore caricamento chat: ${error}`));
            setEditingDevice(null);
        } catch (error) {
            antdMessage.error('Failed to save the chat.');
        }
    };

    const onEdit = (device: Device) => {
        form.setFieldsValue(device);
        setEditingDevice(device);
    };

    const onDelete = async (id: string) => {
        try {
            await deleteDevice(id);
            antdMessage.success('Device deleted successfully!');
            fetchDevices()
                .catch(error =>
                    antdMessage.error(`Errore caricamento chat: ${error}`));
        } catch (error) {
            antdMessage.error('Failed to delete the chat.');
        }
    };


    const columns: TableColumnsType<Device> = [
        {
            title: 'UserLiName',
            dataIndex: 'userLiName',
            key: 'userLiName',
            fixed: 'left',
        },
        {
            title: 'Liid',
            dataIndex: 'liid',
            key: 'liid',
        },
        {
            title: 'NumericalLatitude',
            dataIndex: 'numericalLatitude',
            key: 'numericalLatitude'
        },
        {
            title: 'NumericalLongitude',
            dataIndex: 'numericalLongitude',
            key: 'numericalLongitude'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Speed',
            dataIndex: 'speed',
            key: 'speed'
        },
        {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            width: 180,
            render: (_: any, record: Device) => (
                <>
                    <Tooltip title="Modifica">
                        <Button style={{marginRight: 8}} icon={<IoPencilOutline/>}
                                onClick={() => onEdit(record)}></Button>
                    </Tooltip>
                    <Tooltip title="Elimina simulazione">
                        <Button style={{marginLeft: 20, marginRight: 8}} icon={<IoTrashOutline/>} danger
                                onClick={() => onDelete(record.id!)}></Button>
                    </Tooltip>
                </>
            ),
        },
    ];

    return (
        <div style={{marginLeft: 50, marginRight: 50}}>
            <h2 style={{width: "100%", textAlign: "center"}}>Gestione dei dispositivi</h2>
            <Form form={form}
                  onFinish={onFinish}
                  layout="vertical"
                  style={{marginLeft: 50, marginRight: 50}}
                  initialValues={{
                      userLiName: `test${(Math.random() * 1000).toFixed(0)}`,
                      liid: `${(Math.random() * 1000000).toFixed(0)}`,
                      idPosition: `${(Math.random() * 1000000).toFixed(0)}`,
                      status: "R",
                      numericalLatitude: 45,
                      numericalLongitude: 9,
                      speed: 0,
                      direction: 0,
                      streetViewDirection: 0,
                      stringDirection: "SudEst",
                      altitude: 100,
                      NSat: 5,
                      campoGsm: "0",
                      formattedSatDate: "08-05-2024 07:17:08",
                      address: "Via Luigi Zoja, 35, Milano, Milano, Lombardia, Italia",
                      batteryLevel: "80",
                      stopDuration: null,
                      stopDurationAccuracy: "",
                      trackingStatus: 1,
                      trackingVisible: true,
                      NSatIconIndex: 0,
                      NSatMaxValue: 12,
                      campoGsmIconIndex: 4,
                      campoGsmMaxValue: 30,
                      batteryLevelIconIndex: 3,
                      batteryLevelMaxValue: 100,
                      batteryRemainDuration: "00:00",
                      deviceAlias: "869604063518111",
                      deviceType: "NXG",
                      deviceStatus: "O",
                      devicePhoneNumber: "+882360018897608",
                      liStatus: "ACT",
                      liType: "GPS",
                      liTargetType: "GPS",
                      markerColor: "FF99B3",
                      markerColorSu: "2282F4",
                      liFormattedOpenDate: "18-04-2024 09:32:33",
                      liFormattedDeadlineDate: "31-12-2099 23:59:59",
                      liFormattedEndValidDate: "31-12-2099 23:59:59",
                      liFormattedDefaultDeadlineDate: "31-12-2099 23:59:59",
                      geocodeApproximationLevel: "1",
                      // true,
                      note: "nota",
                      pauseStopDeviceAnnotations: ["nota"]
                  }}
            >
                <Row gutter={[30, 0]}>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="userLiName"
                            label="UserLiName"
                            rules={[{required: true, message: 'Inserire lo User Li Name!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="liid"
                            label="Liid"
                            rules={[{required: true, message: 'Inserire il Liid!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="status"
                            label="Status"
                            rules={[{required: true, message: 'Inserire status!'}]}
                        >
                            <Select>
                                <Option value={"R"}>R - Movimento</Option>
                                <Option value={"S"}>S - Sosta</Option>
                                <Option value={"X"}>X - Non raggiungibile</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="numericalLatitude"
                            label="numericalLatitude"
                            rules={[{required: true, message: 'Inserire numericalLatitude!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="numericalLongitude"
                            label="numericalLongitude"
                            rules={[{required: true, message: 'Inserire numericalLongitude!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="speed"
                            label="speed"
                            rules={[{required: true, message: 'Inserire speed!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="markerColor"
                            label="markerColor"
                            rules={[{required: true, message: 'Inserire markerColor!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                </Row>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Button type="link" onClick={toggleFields} style={{marginBottom: 10}}>
                        {showAdditionalFields ? "Nascondi campi aggiuntivi" : "Mostra campi aggiuntivi"}
                        {showAdditionalFields ? <UpOutlined/> : <DownOutlined/>}
                    </Button>
                </div>
                <Row gutter={[30, 0]} style={{display: showAdditionalFields ? "flex" : "none", width: '100%'}}>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="idPosition"
                            label="IdPosition"
                            rules={[{required: true, message: `Inserire IdPosition!`}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="direction"
                            label="direction"
                            rules={[{required: true, message: 'Inserire direction!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="stringDirection"
                            label="stringDirection"
                            rules={[{required: true, message: 'Inserire stringDirection!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="streetViewDirection"
                            label="streetViewDirection"
                            rules={[{required: true, message: 'Inserire streetViewDirection!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="altitude"
                            label="altitude"
                            rules={[{required: true, message: 'Inserire altitude!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="NSat"
                            label="NSat"
                            rules={[{required: true, message: 'Inserire NSat!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="campoGsm"
                            label="campoGsm"
                            rules={[{required: true, message: 'Inserire campoGsm!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="formattedSatDate"
                            label="formattedSatDate"
                            rules={[{required: true, message: 'Inserire formattedSatDate!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="address"
                            label="address"
                            rules={[{required: true, message: 'Inserire address!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="batteryLevel"
                            label="batteryLevel"
                            rules={[{required: true, message: 'Inserire batteryLevel!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="stopDuration"
                            label="stopDuration"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="stopDurationAccuracy"
                            label="stopDurationAccuracy"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="trackingStatus"
                            label="trackingStatus"
                            rules={[{required: true, message: 'Inserire trackingStatus!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="trackingVisible"
                            label="trackingVisible"
                            rules={[{required: true, message: 'Inserire trackingVisible!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="NSatIconIndex"
                            label="NSatIconIndex"
                            rules={[{required: true, message: 'Inserire NSatIconIndex!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="NSatMaxValue"
                            label="NSatMaxValue"
                            rules={[{required: true, message: 'Inserire NSatMaxValue!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="campoGsmIconIndex"
                            label="campoGsmIconIndex"
                            rules={[{required: true, message: 'Inserire campoGsmIconIndex!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="campoGsmMaxValue"
                            label="campoGsmMaxValue"
                            rules={[{required: true, message: 'Inserire campoGsmMaxValue!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="NSatIconIndex"
                            label="NSatIconIndex"
                            rules={[{required: true, message: 'Inserire NSatIconIndex!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="batteryLevelIconIndex"
                            label="batteryLevelIconIndex"
                            rules={[{required: true, message: 'Inserire batteryLevelIconIndex!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="batteryLevelMaxValue"
                            label="batteryLevelMaxValue"
                            rules={[{required: true, message: 'Inserire batteryLevelMaxValue!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="batteryRemainDuration"
                            label="batteryRemainDuration"
                            rules={[{required: true, message: 'Inserire batteryRemainDuration!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="NSatIconIndex"
                            label="NSatIconIndex"
                            rules={[{required: true, message: 'Inserire NSatIconIndex!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="deviceAlias"
                            label="deviceAlias"
                            rules={[{required: true, message: 'Inserire deviceAlias!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="deviceType"
                            label="deviceType"
                            rules={[{required: true, message: 'Inserire deviceType!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="deviceStatus"
                            label="deviceStatus"
                            rules={[{required: true, message: 'Inserire deviceStatus!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="devicePhoneNumber"
                            label="devicePhoneNumber"
                            rules={[{required: true, message: 'Inserire devicePhoneNumber!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="liStatus"
                            label="liStatus"
                            rules={[{required: true, message: 'Inserire liStatus!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="liType"
                            label="liType"
                            rules={[{required: true, message: 'Inserire liType!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="liTargetType"
                            label="liTargetType"
                            rules={[{required: true, message: 'Inserire liTargetType!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="markerColorSu"
                            label="markerColorSu"
                            rules={[{required: true, message: 'Inserire markerColorSu!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="liFormattedOpenDate"
                            label="liFormattedOpenDate"
                            rules={[{required: true, message: 'Inserire liFormattedOpenDate!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="liFormattedDeadlineDate"
                            label="liFormattedDeadlineDate"
                            rules={[{required: true, message: 'Inserire liFormattedDeadlineDate!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="liFormattedEndValidDate"
                            label="liFormattedEndValidDate"
                            rules={[{required: true, message: 'Inserire liFormattedEndValidDate!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="liFormattedDefaultDeadlineDate"
                            label="liFormattedDefaultDeadlineDate"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="geocodeApproximationLevel"
                            label="geocodeApproximationLevel"
                            rules={[{required: true, message: 'Inserire geocodeApproximationLevel!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="note"
                            label="note"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{width: 190}}
                            name="pauseStopDeviceAnnotations"
                            label="pauseStopDeviceAnnotations"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {editingDevice ? 'Aggiorna il dispositivo' : 'Salva il dispositivo'}
                        </Button>
                        <Button type="default" onClick={() => setIsMapModalVisible(true)}
                                style={{marginLeft: '10px'}}>
                            Seleziona il punto sulla mappa
                        </Button>

                    </div>
                </Form.Item>
            </Form>
            <Modal
                title="Seleziona il punto di locazione del dispositivo"
                open={isMapModalVisible}
                footer={null}
                onCancel={() => setIsMapModalVisible(false)}
                width={850}
                style={{top: 20, padding: 0}}  // Imposta la posizione del modale
            >
                <div style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                }}>
                    <MapSelectorSinglePoint onSelect={handleMapSelect}/>
                </div>
            </Modal>
            <Table columns={columns} dataSource={devices} rowKey="id" loading={loading}/>
        </div>
    );
};

export default DeviceManagement;
