export enum ChatType {
    CHAT = "CHAT",
    CHASE = "CHASE",
    FLEET = "FLEET",
}

export interface CreateChat {
    group: boolean;
    type?: ChatType;
    author: string
    title?: string;
    info?: string;
    image?: string;
    members: string[];
}

export interface Chat extends CreateChat {
    id: string;
}
