import React, {FC, useRef} from 'react';
import {Button, List, Tag, Tooltip, Divider, Row, Col} from 'antd';
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import {IoLockClosed, IoLockOpen, IoPencilOutline, IoPlayOutline, IoTrashOutline} from 'react-icons/io5';
import {XYCoord} from 'dnd-core';
import {TestState, TestStep} from "../../types/TestChain";
import SimulateMessages from "../chat/SimulateMessages";
import {queries} from "@testing-library/react";
import SimulateChats from "../chat/SimulateChats";
import SimulateChases from "../chase/SimulateChases";
import SimulateStompConnections from "../stompconnection/SimulateStompConnections";
import PauseForm from "./PauseForm";

interface DraggableTestItemProps {
    item: TestStep;
    index: number;
    moveTest: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (index: number, testChain: TestStep[]) => void;
    testChain: TestStep[];
    onEdit: (index: number) => void;
    onRun: (index: number) => void;
    editingTest: { index: number, test: TestStep } | null;
    setEditingTest: React.Dispatch<React.SetStateAction<{ index: number, test: TestStep } | null>>;
    saveTestEdits: (editingTest: { index: number, test: TestStep }) => void;
    form: any;
    changeBlocking: (item: number) => void,
}

const type = 'DraggableTest';

interface DragItem {
    index: number;
    type: string;
}

const DraggableTestItem: FC<DraggableTestItemProps> = ({
                                                           item,
                                                           index,
                                                           moveTest,
                                                           onDelete,
                                                           testChain,
                                                           onEdit,
                                                           onRun,
                                                           editingTest,
                                                           setEditingTest,
                                                           saveTestEdits,
                                                           form,
                                                           changeBlocking
                                                       }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: type,
        hover(item: any, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveTest(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{isDragging}, drag] = useDrag({
        type,
        item: {type, index},
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const getStateTag = (state: TestState) => {
        switch (state) {
            case TestState.WAITING:
                return <Tag color="blue">In attesa</Tag>;
            case TestState.RUNNING:
                return <Tag color="orange">In esecuzione</Tag>;
            case TestState.SUCCESS:
                return <Tag color="green">Successo</Tag>;
            case TestState.ERROR:
                return <Tag color="red">Errore</Tag>;
            default:
                return null;
        }
    };

    const printTestParams = (test: TestStep): string => {
        switch (test.testType) {
            case 'chat':
                return `Numero di chat: ${test.testParams.quantity}, sorgente: ${test.testParams.fromDB ? 'Database' : 'Casuali'}, frequenza: ${test.testParams.nPerMinute} chat al minuto`;
            case 'message':
                return `Numero di messaggi: ${test.testParams.quantity}, sorgente: ${test.testParams.fromDB ? 'Database' : 'Casuali'}, frequenza: ${test.testParams.nPerMinute} messaggi al minuto`;
            case 'chase':
                return `Numero di inseguimenti: ${test.testParams.quantity}, sorgente: ${test.testParams.fromDB ? 'Database' : 'Casuali'}, frequenza: ${test.testParams.nPerMinute} inseguimenti al minuto`;
            case 'stomp':
                return `Numero di connessioni: ${test.testParams.numberOfConnections}, server: ${test.testParams.type}, durata: ${test.testParams.durationInSeconds} secondi`;
            case 'pause':
                return `Durata: ${test.testParams.duration} secondi`;
            default:
                return "null";
        }
    };

    return (
        <>
            <List.Item
                ref={ref}
                style={{opacity: isDragging ? 0.5 : 1}}
                key={index}
                actions={[
                    <Tooltip title={item.isBlocking ? "Rendi non bloccante" : "Rendi bloccante"}>
                        <Button
                            style={{marginLeft: 4, marginRight: 8}}
                            type="primary"
                            onClick={() => changeBlocking(index)}
                            icon={item.isBlocking ? <IoLockOpen/> : <IoLockClosed/>}
                        >
                        </Button>
                    </Tooltip>,
                    <Tooltip title="Esegui il test">
                        <Button
                            style={{marginLeft: 4, marginRight: 8}}
                            type="primary"
                            onClick={() => onRun(index)}
                            icon={<IoPlayOutline/>}
                        >
                        </Button>
                    </Tooltip>,
                    <Tooltip title="Modifica il test">
                        <Button
                            style={{marginLeft: 4, marginRight: 8}}
                            type="default"
                            onClick={() => onEdit(index)}
                            icon={<IoPencilOutline/>}
                        >
                        </Button>
                    </Tooltip>,
                    <Tooltip title="Elimina il test">
                        <Button
                            style={{marginLeft: 20, marginRight: 8}}
                            icon={<IoTrashOutline/>}
                            danger
                            type="primary"
                            onClick={() => onDelete(index, testChain)}
                        />
                    </Tooltip>,
                ]}
            >
                <List.Item.Meta
                    title={`${item.testType}`}
                    description={printTestParams(item)}
                />
                {getStateTag(item.testState)}
            </List.Item>
            {editingTest && editingTest.index === index && (
                <>
                    <div style={{width: "100%", justifyContent: "center", marginBottom: 0}}>
                        <Row justify="center">
                            <Col span={24}>
                                {
                                    item.testType === 'message' ? (
                                            <SimulateMessages
                                                initialValues={item.testParams}
                                                buttonTitle={"Salva modifiche"}
                                                onSimulate={(quantity, fromDB, nPerMinute) => {
                                                    // setEditingTest()
                                                    saveTestEdits({
                                                        index: index,
                                                        test: {
                                                            testType: 'message',
                                                            testParams: {
                                                                quantity: quantity,
                                                                fromDB: fromDB,
                                                                nPerMinute: nPerMinute
                                                            },
                                                            testState: TestState.WAITING,
                                                            isBlocking: item.isBlocking
                                                        }
                                                    })
                                                }}
                                            />
                                        ) :
                                        item.testType === 'chat' ? (
                                                <SimulateChats
                                                    initialValues={item.testParams}
                                                    buttonTitle={"Salva modifiche"}
                                                    onSimulate={(quantity, fromDB, nPerMinute) => {
                                                        // setEditingTest()
                                                        saveTestEdits({
                                                            index: index,
                                                            test: {
                                                                testType: 'chat',
                                                                testParams: {
                                                                    quantity: quantity,
                                                                    fromDB: fromDB,
                                                                    nPerMinute: nPerMinute
                                                                },
                                                                testState: TestState.WAITING,
                                                                isBlocking: item.isBlocking
                                                            }
                                                        })
                                                    }}
                                                />
                                            ) :
                                            item.testType === 'chase' ? (
                                                    <SimulateChases
                                                        initialValues={item.testParams}
                                                        buttonTitle={"Salva modifiche"}
                                                        onSimulate={(quantity, fromDB, nPerMinute) => {
                                                            // setEditingTest()
                                                            saveTestEdits({
                                                                index: index,
                                                                test: {
                                                                    testType: 'chase',
                                                                    testParams: {
                                                                        quantity: quantity,
                                                                        fromDB: fromDB,
                                                                        nPerMinute: nPerMinute
                                                                    },
                                                                    testState: TestState.WAITING,
                                                                    isBlocking: item.isBlocking
                                                                }
                                                            })
                                                        }}
                                                    />
                                                )
                                                :
                                                item.testType === "stomp" ? (
                                                        <SimulateStompConnections
                                                            initialValues={item.testParams}
                                                            buttonTitle={"Salva modifiche"}
                                                            onSimulate={(numberOfConnections, type, durationInSeconds) => {
                                                                // setEditingTest()
                                                                console.log(type)
                                                                saveTestEdits({
                                                                    index: index,
                                                                    test: {
                                                                        testType: 'stomp',
                                                                        testParams: {
                                                                            numberOfConnections: numberOfConnections,
                                                                            type: type,
                                                                            durationInSeconds: durationInSeconds
                                                                        },
                                                                        testState: TestState.WAITING,
                                                                        isBlocking: item.isBlocking
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    ) :
                                                    (
                                                        <PauseForm
                                                            initialValues={item.testParams}
                                                            buttonTitle={"Salva modifiche"}
                                                            onSimulate={(duration) => {
                                                                saveTestEdits({
                                                                    index: index,
                                                                    test: {
                                                                        testType: 'pause',
                                                                        testParams: {
                                                                            duration: duration
                                                                        },
                                                                        testState: TestState.WAITING,
                                                                        isBlocking: item.isBlocking
                                                                    }
                                                                })
                                                            }}
                                                        />)
                                }
                            </Col>
                        </Row>
                    </div>
                    <Divider style={{margin: '0'}}/>
                </>
            )}
        </>
    );
};

export default DraggableTestItem;
