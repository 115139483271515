import React, {useState, useCallback} from 'react';
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import {Button} from 'antd';

const containerStyle = {
    width: '800px',
    height: '600px'
};

const center = {
    lat: 45.064701,
    lng: 7.673420
};

const MapSelector: React.FC<{ onSelect: (start: { lat: number, lng: number }, end: { lat: number, lng: number }) => void }> = ({onSelect}) => {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCxm2hayp7_5XjXpqwmws_RGrcJOlUkfC0'
    });

    // const [map, setMap] = useState<google.maps.Map | null>(null);
    const [markers, setMarkers] = useState<{ lat: number, lng: number }[]>([]);


    // const onLoad = useCallback((mapInstance: google.maps.Map) => {
    //     setMap(mapInstance);
    // }, []);

    const handleConfirm = () => {
        if (markers.length === 2) {
            onSelect(markers[0], markers[1]);
        }
    };
    const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
        if (markers.length < 2) {
            setMarkers([...markers, {lat: event.latLng!.lat(), lng: event.latLng!.lng()}]);
            if (markers.length === 2) {
                handleConfirm()
            }
        }
    }, [handleConfirm, markers]);

    const handleReset = () => {
        setMarkers([])
    }
    return isLoaded ? (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                onClick={onMapClick}
                // onLoad={onLoad}
            >
                <Marker key={"1"} position={markers[0]} label={"Partenza"}>
                </Marker>
                <Marker key={"2"} position={markers[1]} label={"Arrivo"}>
                </Marker>
            </GoogleMap>
            <Button type="primary" onClick={handleConfirm} disabled={markers.length < 2}>Conferma</Button>
            <Button onClick={handleReset} disabled={markers.length === 0}>Resetta</Button>
        </>
    ) : <></>;
}

export default MapSelector;
