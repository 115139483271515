import axios from 'axios';

const API_BASE_URL = '/API/simulations';

export const createSimulation = async (simulation: any) => {
    const response = await axios.post(API_BASE_URL, simulation);
    return response.data;
};

export const getAllSimulations = async () => {
    const response = await axios.get(API_BASE_URL);
    return response.data;
};

export const updateSimulation = async (id: string, simulation: any) => {
    const response = await axios.put(`${API_BASE_URL}/${id}`, simulation);
    return response.data;
};

export const deleteSimulation = async (id: string) => {
    const response = await axios.delete(`${API_BASE_URL}/${id}`);
    return response.data;
};

export const startSimulation = async (deviceLiid: string, startLat: number, startLng: number, endLat: number, endLng: number, speed: number) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/start`, null, {
            params: {
                deviceLiid,
                startLat,
                startLng,
                endLat,
                endLng,
                speed,
            },
        });
        return response;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            return error.response;
        } else {
            throw error;
        }
    }
}