import {
    Button,
    Col,
    Form,
    Input,
    message as antdMessage,
    Row,
    Select,
    Table,
    TableColumnsType,
    Tag,
    Tooltip
} from "antd";
import React, {useEffect, useState} from "react";
import {IoPencilOutline, IoPlayOutline, IoTrashOutline} from "react-icons/io5";
import {Chase, CreateChase} from "../../types/Chase";
import {
    deleteChase,
    getChases,
    saveChase,
    simulateNewChase,
    simulateNewChases,
    updateChase
} from "../../service/chaseService";
import SimulateChases from "./SimulateChases";

const ChaseManagement = () => {
    const [form] = Form.useForm();
    const [chasesLocal, setChasesLocal] = useState<Chase[]>([]);
    const [loading, setLoading] = useState(false);
    const [editingChat, setEditingChat] = useState<Chase | null>(null);
    const [chaser, setChaser] = useState<string[]>([]);
    const [chased, setChased] = useState<string[]>([]);
    const [inputChaser, setInputChaser] = useState<string>('');
    const [inputChased, setInputChased] = useState<string>('');
    const fetchChases = async () => {
        setLoading(true);
        try {
            const data = await getChases();
            setChasesLocal(data);
        } catch (error) {
            antdMessage.error('Errore caricamento inseguimenti.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchChases();
    }, []);

    const onFinish = async (values: CreateChase) => {
        console.log(values)
        const chaseValues = {...values, chaser, chased};
        try {
            if (editingChat) {
                await updateChase({...chaseValues, id: editingChat.id, creationDate: editingChat.creationDate, closeDate: editingChat.closeDate});
                antdMessage.success('Chat modificata con successo!');
            } else {
                await saveChase(chaseValues);
                antdMessage.success('Chat salvata con successo!');
            }
            form.resetFields();
            setChased([]);
            setChaser([]);
            setChaser(["1", "2"]);
            setChased(["1", "2"]);
            fetchChases();
            setEditingChat(null);
        } catch (error) {
            antdMessage.error('Errore salvataggio della chat.');
        }
    };

    const onEdit = (chase: Chase) => {
        form.setFieldsValue(chase);
        setChaser(chase.chaser);
        setChased(chase.chased);
        setEditingChat(chase);
    };

    const onDelete = async (id: string) => {
        try {
            await deleteChase(id);
            antdMessage.success('Inseguimento eliminato con successo!');
            fetchChases();
        } catch (error) {
            antdMessage.error('Errore durante la cancellazione dell\'inseguimento.');
        }
    };
    const onSimulate = async (chase: Chase) => {
        try {
            await simulateNewChase(chase);
            antdMessage.success("Inseguimento creato con successo!")
        } catch (error) {
            antdMessage.error("Errore durante la simulazione di creazione dell'inseguimento.")
        }
    }
    const handleSimulate = async (quantity: number, fromDB: boolean, nPerMinute: number) => {
        setLoading(true);
        try {
            const response = await simulateNewChases(quantity, fromDB, nPerMinute)

            if (response.status === 200) {
                antdMessage.success("Creazione degli inseguimenti avvenuta con successo")
            } else {
                antdMessage.error("Errore durante la simulazione di creazione degli inseguimenti");
            }
        } catch (error) {
            antdMessage.error(`Errore durante la simulazione di creazione degli inseguimenti: ${error}`);
        }
        setLoading(false);
    };

    const handleInputChaserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputChaser(e.target.value);
    };
    const handleInputChasedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputChased(e.target.value);
    };
    const handleInputChaserConfirm = () => {
        if (inputChaser && !chaser.includes(inputChaser)) {
            setChaser([...chaser, inputChaser]);
        }
        setInputChaser('');
    };
    const handleInputChasedConfirm = () => {
        if (inputChased && !chased.includes(inputChased)) {
            setChased([...chased, inputChased]);
        }
        setInputChased('');
    };
    const handleRemoveChaser = (chaserId: string) => {
        setChaser(chaser.filter(c => c !== chaserId));
    };
    const handleRemoveChased = (chasedId: string) => {
        setChased(chased.filter(c => c !== chasedId));
    };

    const columns: TableColumnsType<Chase> = [
        {
            title: 'Nome inseguimento',
            dataIndex: 'title',
            key: 'title',
            fixed: 'left',
        },
        {
            title: 'Autore',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: 'Inseguitori',
            dataIndex: 'chaser',
            key: 'chaser',
            render: (chaser: string[]) => chaser.join(', '),
        },
        {
            title: 'Inseguiti',
            dataIndex: 'chased',
            key: 'chased',
            render: (chased: string[]) => chased.join(', '),
        },
        {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            width: 180,
            render: (_: any, record: Chase) => (
                <div style={{flexDirection: "row", height: 20, justifyItems: "center", justifyContent: "center"}}>
                    <Tooltip title="Modifica l'inseguimento">
                        <Button style={{marginRight: 8}} icon={<IoPencilOutline/>} type="primary"
                                onClick={() => onEdit(record)}></Button>
                    </Tooltip>
                    <Tooltip title="Simula creazione dell'inseguimento">
                        <Button style={{marginRight: 8}} icon={<IoPlayOutline/>} type="primary"
                                onClick={() => onSimulate(record)}></Button>
                    </Tooltip>
                    <Tooltip title="Elimina l'inseguimento">
                        <Button style={{marginLeft: 20, marginRight: 8}} icon={<IoTrashOutline/>} danger type="primary"
                                onClick={() => onDelete(record.id!)}></Button>
                    </Tooltip>
                </div>
            ),
        },
    ];
    const handleKeyDownChaser = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Previene il submit del form
            handleInputChaserConfirm();
        }
    };
    const handleKeyDownChased = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Previene il submit del form
            handleInputChasedConfirm();
        }
    };
    useEffect(() => {
        setChaser(["1", "2"])
    }, []);
    useEffect(() => {
        setChased(["1", "2"])
    }, []);
    return (
        <>
            <h2 style={{width: "100%", textAlign: "center"}}>Gestione degli inseguimenti</h2>
            <div style={{display: "flex", width: "100%", justifyContent: "center", justifyItems: "center"}}>
                <Form
                    initialValues={{
                        title: `Inseguimento${(Math.random() * 1000).toFixed(0)}`,
                        author: "1",
                        chaser: ["1", "2"],
                        chased: ["1", "2"]
                    }}
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    style={{
                        justifyContent: "center",
                        justifyItems: "center",
                        justifySelf: "center",
                        marginLeft: 50,
                        marginRight: 50
                    }}>
                    <Row gutter={[40, 0]}>
                        <Col xs={24} sm={8} md={6}>
                            <Form.Item
                                name="title"
                                label="Nome dell'inseguimenti"
                                rules={[{required: true, message: "Selezionare il nome dell'inseguimento!"}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={6}>
                            <Form.Item
                                name="author"
                                label="Autore dell'inseguimento"
                                rules={[{required: true, message: "Selezionare l'autore dell'inseguimento!"}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={6}>
                            <Form.Item label="Inseguitori">
                                <div>
                                    {chaser.map((c) => (
                                        <Tag
                                            key={c}
                                            closable
                                            onClose={() => handleRemoveChaser(c)}
                                        >
                                            {c}
                                        </Tag>
                                    ))}
                                    <Input
                                        type="text"
                                        value={inputChaser}
                                        onChange={handleInputChaserChange}
                                        // onPressEnter={handleInputConfirm}
                                        onKeyDown={handleKeyDownChaser}
                                        placeholder="Inserire un inseguitore e premere Invio"
                                    />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={6}>
                            <Form.Item label="Inseguiti">
                                <div>
                                    {chased.map((c) => (
                                        <Tag
                                            key={c}
                                            closable
                                            onClose={() => handleRemoveChased(c)}
                                        >
                                            {c}
                                        </Tag>
                                    ))}
                                    <Input
                                        type="text"
                                        value={inputChased}
                                        onChange={handleInputChasedChange}
                                        // onPressEnter={handleInputConfirm}
                                        onKeyDown={handleKeyDownChased}
                                        placeholder="Inserire un inseguito e premere Invio"
                                    />
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {editingChat ? 'Modifica inseguimento' : 'Salva inseguimento'}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>

            <SimulateChases onSimulate={handleSimulate}/>

            <Table style={{minWidth: 700}} columns={columns} dataSource={chasesLocal} scroll={{x: 500}} rowKey="id"
                   loading={loading}/>
        </>
    );
}

export default ChaseManagement