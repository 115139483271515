import axios from 'axios';
import {Chase, CreateChase} from "../types/Chase";

const API_URL = '/API/chases';

export const saveChase = async (chase: CreateChase): Promise<Chase> => {
    const response = await axios.post(API_URL, chase);
    return response.data;
};

export const getChases = async (): Promise<Chase[]> => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const updateChase = async (chase: Chase): Promise<Chase> => {
    const response = await axios.put(API_URL, chase);
    return response.data;
};

export const deleteChase = async (id: string): Promise<void> => {
    await axios.delete(`${API_URL}/${id}`);
};

export const simulateNewChase = async (chase: Chase) => {
    return await axios.post(`${API_URL}/simulate-new-chase`, chase)
}

export const simulateNewChases = async (quantity: number, fromBD: boolean, nPerMinute: number) => {
    return axios.post(`${API_URL}/simulate-new-chases`,
        {quantity: quantity, fromDB: fromBD, nPerMinute: nPerMinute})
}