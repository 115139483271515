import React, { useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, DrawingManager } from '@react-google-maps/api';
import { Button } from 'antd';
import {google_api_key} from "../consts";

const containerStyle = {
    width: '800px',
    height: '600px',
};

const center = {
    lat: 45.064701,
    lng: 7.67342,
};

interface MapSelectorShapeProps {
    onSelect: (shapeType: string, coordinatesList: number[][]) => void;
}

const MapSelectorShape: React.FC<MapSelectorShapeProps> = ({ onSelect }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: google_api_key,
        libraries: ['drawing', 'geometry'],
    });

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [selectedShape, setSelectedShape] = useState<
        google.maps.Marker | google.maps.Polygon | google.maps.Circle | null
    >(null);
    const [shapeType, setShapeType] = useState<string>('');
    const [coordinatesList, setCoordinatesList] = useState<number[][]>([]);

    const onLoad = useCallback((mapInstance: google.maps.Map) => {
        setMap(mapInstance);
    }, []);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const handleConfirm = () => {
        if (selectedShape) {
            onSelect(shapeType, coordinatesList);
        }
    };

    const handleDeleteShape = () => {
        if (selectedShape) {
            selectedShape.setMap(null);
            setSelectedShape(null);
            setShapeType('');
            setCoordinatesList([]);
        }
    };

    const clearSelection = () => {
        if (selectedShape) {
            selectedShape.setMap(null);
            setSelectedShape(null);
        }
    };

    const handleOverlayComplete = (e: google.maps.drawing.OverlayCompleteEvent) => {
        clearSelection(); // Remove previous shape
        let newShape: google.maps.Marker | google.maps.Polygon | google.maps.Circle | null = null;
        setShapeType(e.type.toUpperCase());

        if (e.type === google.maps.drawing.OverlayType.MARKER) {
            newShape = e.overlay as google.maps.Marker;
            const position = newShape.getPosition();
            if (position) {
                setCoordinatesList([[position.lat(), position.lng()]]);
            }
        } else if (e.type === google.maps.drawing.OverlayType.CIRCLE) {
            newShape = e.overlay as google.maps.Circle;
            const center = newShape.getCenter();
            const radius = newShape.getRadius();
            if (center && radius) {
                const circumferencePoint = google.maps.geometry.spherical.computeOffset(
                    center,
                    radius,
                    0
                );
                setCoordinatesList([
                    [center.lat(), center.lng()],
                    [circumferencePoint.lat(), circumferencePoint.lng()],
                ]);
            }
        } else if (e.type === google.maps.drawing.OverlayType.POLYGON) {
            newShape = e.overlay as google.maps.Polygon;
            const path = newShape.getPath();
            const coords: number[][] = [];
            for (let i = 0; i < path.getLength(); i++) {
                const point = path.getAt(i);
                coords.push([point.lat(), point.lng()]);
            }
            setCoordinatesList(coords);
        }

        setSelectedShape(newShape);

        // Disable drawing mode after shape is drawn
        drawingManager?.setDrawingMode(null);
    };

    let drawingManager: google.maps.drawing.DrawingManager;

    const onDrawingManagerLoad = (drawingManagerInstance: google.maps.drawing.DrawingManager) => {
        drawingManager = drawingManagerInstance;
    };

    return isLoaded ? (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <DrawingManager
                    onLoad={onDrawingManagerLoad}
                    onOverlayComplete={handleOverlayComplete}
                    options={{
                        drawingControl: true,
                        drawingControlOptions: {
                            position: google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [
                                google.maps.drawing.OverlayType.MARKER,
                                google.maps.drawing.OverlayType.CIRCLE,
                                google.maps.drawing.OverlayType.POLYGON,
                            ],
                        },
                        markerOptions: {
                            draggable: true,
                        },
                        circleOptions: {
                            fillColor: '#ffff00',
                            fillOpacity: 0.2,
                            strokeWeight: 2,
                            clickable: true,
                            editable: true,
                            zIndex: 1,
                        },
                        polygonOptions: {
                            fillColor: '#ffff00',
                            fillOpacity: 0.2,
                            strokeWeight: 2,
                            clickable: true,
                            editable: true,
                            zIndex: 1,
                        },
                    }}
                />
            </GoogleMap>
            <div style={{ marginTop: '10px' }}>
                <Button
                    type="primary"
                    onClick={handleConfirm}
                    disabled={!selectedShape}
                    style={{ marginRight: '10px' }}
                >
                    Confirm
                </Button>
                <Button type="default" onClick={handleDeleteShape} disabled={!selectedShape}>
                    Delete Shape
                </Button>
            </div>
        </>
    ) : (
        <></>
    );
};

export default MapSelectorShape;
