export interface StompConnectionRequest {
    numberOfConnections: number;
    type: ServerType;
    durationInSeconds: number;
}

export enum ServerType{
    CHAT,
    PROXY,
    BOTH
}