import React, { useEffect, useState } from 'react';
import {
    Form,
    Input,
    Button,
    Select,
    Table,
    message as antdMessage,
    Tooltip,
    Row,
    Col,
    Modal,
    TableColumnsType,
} from 'antd';
import { IoPencilOutline, IoTrashOutline } from 'react-icons/io5';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { POI } from '../../types/POI';
import { getPOIs, savePOI, updatePOI, deletePOI } from '../../service/POIService';
import { Device } from '../../types/Device';
import { getDevices } from '../../service/DeviceService';
import MapSelectorShape from './MapSelectorShape';

const { Option } = Select;

const POIManagement: React.FC = () => {
    const [form] = Form.useForm();
    const [pois, setPOIs] = useState<POI[]>([]);
    const [devices, setDevices] = useState<Device[]>([]);
    const [loading, setLoading] = useState(false);
    const [editingPOI, setEditingPOI] = useState<POI | null>(null);
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [isMapModalVisible, setIsMapModalVisible] = useState<boolean>(false);
    const [coordinatesList, setCoordinatesList] = useState<number[][]>([]);

    const fetchPOIs = async () => {
        setLoading(true);
        try {
            const data = await getPOIs();
            setPOIs(data);
        } catch (error) {
            antdMessage.error('Failed to load POIs.');
        } finally {
            setLoading(false);
        }
    };

    const fetchDevices = async () => {
        try {
            const data = await getDevices();
            setDevices(data);
        } catch (error) {
            antdMessage.error('Failed to load devices.');
        }
    };

    useEffect(() => {
        fetchPOIs();
        fetchDevices();
    }, []);

    const generateRandomData = () => {
        const randomName = `POI_${Math.floor(Math.random() * 1000)}`;
        const randomIsPublic = Math.random() < 0.5;
        const randomShapeType = ['POINT', 'CIRCLE', 'POLYGON'][
            Math.floor(Math.random() * 3)
            ] as 'POINT' | 'CIRCLE' | 'POLYGON';

        let randomCoordinatesList: number[][] = [];
        if (randomShapeType === 'POINT') {
            const lat = 45 + Math.random();
            const lng = 9 + Math.random();
            randomCoordinatesList = [[lat, lng]];
        } else if (randomShapeType === 'CIRCLE') {
            const centerLat = 45 + Math.random();
            const centerLng = 9 + Math.random();
            const pointLat = centerLat + 0.01;
            const pointLng = centerLng + 0.01;
            randomCoordinatesList = [
                [centerLat, centerLng],
                [pointLat, pointLng],
            ];
        } else if (randomShapeType === 'POLYGON') {
            randomCoordinatesList = [];
            for (let i = 0; i < 3 + Math.floor(Math.random() * 3); i++) {
                const lat = 45 + Math.random();
                const lng = 9 + Math.random();
                randomCoordinatesList.push([lat, lng]);
            }
        }

        const randomAttachedDevices = devices
            .sort(() => 0.5 - Math.random())
            .slice(0, Math.floor(Math.random() * devices.length))
            .map((device) => device.liid);

        return {
            name: randomName,
            id: '0',
            attachedDeviceList: randomAttachedDevices,
            isPublic: randomIsPublic,
            notes: 'Generated automatically',
            owner: null,
            shapeType: randomShapeType,
            coordinatesList: randomCoordinatesList,
            rcsEventsId: `${randomName}_${randomIsPublic ? 'PUBLIC' : 'PRIVATE'}`,
        };
    };

    useEffect(() => {
        if (devices.length > 0) {
            const initialData = generateRandomData();
            form.setFieldsValue(initialData);
            setCoordinatesList(initialData.coordinatesList || []);
        }
    }, [devices, pois]);

    const onFinish = async (values: any) => {
        const poiValues: POI = {
            ...values,
            coordinatesList,
            attachedDeviceList: values.attachedDeviceList || [],
            owner: null,
            id: '0',
            rcsEventsId: `${values.name}_${values.isPublic ? 'PUBLIC' : 'PRIVATE'}`,
        };
        try {
            if (editingPOI) {
                await updatePOI(poiValues);
                antdMessage.success('POI updated successfully!');
            } else {
                await savePOI(poiValues);
                antdMessage.success('POI saved successfully!');
            }
            form.resetFields();
            setCoordinatesList([]);
            fetchPOIs();
            setEditingPOI(null);
        } catch (error) {
            antdMessage.error('Failed to save the POI.');
        }
    };

    const onEdit = (poi: POI) => {
        form.setFieldsValue(poi);
        setCoordinatesList(poi.coordinatesList);
        setEditingPOI(poi);
    };

    const onDelete = async (rcsEventsId: string) => {
        try {
            await deletePOI(rcsEventsId);
            antdMessage.success('POI deleted successfully!');
            fetchPOIs();
        } catch (error) {
            antdMessage.error('Failed to delete the POI.');
        }
    };

    const toggleFields = () => {
        setShowAdditionalFields(!showAdditionalFields);
    };

    const handleMapSelect = (shapeType: string, selectedCoordinatesList: number[][]) => {
        form.setFieldsValue({
            shapeType,
        });
        setCoordinatesList(selectedCoordinatesList);
        setIsMapModalVisible(false);
    };

    const columns: TableColumnsType<POI> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Shape Type',
            dataIndex: 'shapeType',
            key: 'shapeType',
        },
        {
            title: 'Is Public',
            dataIndex: 'isPublic',
            key: 'isPublic',
            render: (isPublic: boolean) => (isPublic ? 'Yes' : 'No'),
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
        },
        {
            title: 'Devices',
            dataIndex: 'attachedDeviceList',
            key: 'attachedDeviceList',
            render: (attachedDeviceList: string[]) => attachedDeviceList?.length || 0,
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            width: 180,
            render: (_: any, record: POI) => (
                <>
                    <Tooltip title="Edit">
                        <Button
                            style={{ marginRight: 8 }}
                            icon={<IoPencilOutline />}
                            onClick={() => onEdit(record)}
                        ></Button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button
                            style={{ marginLeft: 8 }}
                            icon={<IoTrashOutline />}
                            danger
                            onClick={() => onDelete(record.rcsEventsId)}
                        ></Button>
                    </Tooltip>
                </>
            ),
        },
    ];

    return (
        <div style={{ marginLeft: 50, marginRight: 50 }}>
            <h2 style={{ width: '100%', textAlign: 'center' }}>POI Management</h2>
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                style={{ marginLeft: 50, marginRight: 50 }}
            >
                <Row gutter={[30, 0]}>
                    <Col>
                        <Form.Item
                            style={{ width: 190 }}
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter the name!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{ width: 190 }}
                            name="isPublic"
                            label="Is Public"
                            rules={[{ required: true, message: 'Please select if public!' }]}
                        >
                            <Select>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{ width: 190 }}
                            name="shapeType"
                            label="Shape Type"
                            rules={[{ required: true, message: 'Please select the shape type!' }]}
                        >
                            <Select>
                                <Option value="POINT">Point</Option>
                                <Option value="CIRCLE">Circle</Option>
                                <Option value="POLYGON">Polygon</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item style={{ width: 190 }} name="notes" label="Notes">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[30, 0]}>
                    <Col>
                        <Form.Item label="Coordinates">
                            <Button type="default" onClick={() => setIsMapModalVisible(true)}>
                                {coordinatesList.length > 0 ? 'Edit Shape on Map' : 'Select Shape on Map'}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            style={{ width: 380 }}
                            name="attachedDeviceList"
                            label="Attached Devices"
                            rules={[{ required: false }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select devices"
                                allowClear
                                optionFilterProp="label"
                            >
                                {devices.map((device) => (
                                    <Option key={device.id} value={device.liid} label={device.userLiName}>
                                        {device.userLiName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type="link" onClick={toggleFields} style={{ marginBottom: 10 }}>
                        {showAdditionalFields ? 'Hide Additional Fields' : 'Show Additional Fields'}
                        {showAdditionalFields ? <UpOutlined /> : <DownOutlined />}
                    </Button>
                </div>
                {showAdditionalFields && (
                    <Row gutter={[30, 0]}>
                        {/* Additional fields can be added here if needed */}
                    </Row>
                )}
                <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {editingPOI ? 'Update POI' : 'Save POI'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
            <Modal
                title="Select Shape on Map"
                visible={isMapModalVisible}
                footer={null}
                onCancel={() => setIsMapModalVisible(false)}
                width={850}
                style={{ top: 20, padding: 0 }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    <MapSelectorShape onSelect={handleMapSelect} />
                </div>
            </Modal>
            <Table columns={columns} dataSource={pois} rowKey="rcsEventsId" loading={loading} />
        </div>
    );
};

export default POIManagement;
