import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import axios from "axios";
import {Chat} from "../../types/Chat";

interface ChatsContextType {
    chats: any;
    setChats: React.Dispatch<React.SetStateAction<any>>;
}

const ChatsContext = createContext<ChatsContextType | undefined>(undefined);

export const ChatsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [chats, setChats] = useState<any>();

    useEffect(() => {
        const fetchChats = async () => {
            const response = await axios.get('/API/chats/fromServer');
            const chats = await response.data;
            console.log(chats)
            setChats(chats);
        };
        fetchChats();
    }, []);

    return (
        <ChatsContext.Provider value={{ chats, setChats }}>
            {children}
        </ChatsContext.Provider>
    );
};

export const useChatsContext = (): ChatsContextType => {
    const context = useContext(ChatsContext);
    if (!context) {
        throw new Error('useChatsContext must be used within a ChatsProvider');
    }
    return context;
};
