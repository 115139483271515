import axios from 'axios';
import {TestChain} from "../types/TestChain";

const API_URL = '/API/testChains';

export const saveTestChain = async (testChain: TestChain) => {
    return axios.post(API_URL, testChain);
};

export const getAllTestChains = async () => {
    return axios.get(API_URL);
};

export const getTestChainById = async (id: string) => {
    return axios.get(`${API_URL}/${id}`);
};

export const updateTestChain = async (testChain: TestChain) => {
    return axios.put(`${API_URL}/${testChain.id}`, testChain);
};

export const deleteTestChain = async (id: string) => {
    return axios.delete(`${API_URL}/${id}`);
};
