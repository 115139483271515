// StompConnectionPage.tsx
import React from 'react';
import {message} from 'antd';
import {ServerType, StompConnectionRequest} from "../../types/StompConnection";
import {createStompConnections} from "../../service/StompConnectionService";
import SimulateStompConnections from "./SimulateStompConnections";


const StompConnection: React.FC = () => {

    const onFinish = async (numberOfConnections: number, serverType: ServerType, durationInSeconds: number) => {
        const request: StompConnectionRequest = {
            numberOfConnections: numberOfConnections,
            type: serverType,
            durationInSeconds: durationInSeconds,
        };

        try {
            await createStompConnections(request);
            message.success("Connessioni create con successo");
        } catch (error) {
            message.error('Errore durante la creazione delle connessioni');
        }
    };

    return (
        <div>
            <h1 style={{width: "100%", textAlign: "center"}}>Crea connessioni STOMP</h1>
            <SimulateStompConnections onSimulate={onFinish}/>
        </div>
    );
};

export default StompConnection;
