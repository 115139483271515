import {Routes, Route, useLocation} from "react-router-dom";
import AdminMain from "../components/AdminMain";
import AdminNavBar from "../components/AdminNavBar";
import {useEffect, useState} from "react";
import ChatManagement from "../components/chat/ChatManagement";
import DeviceManagement from "../components/devices/DevicesManagement";
import ChaseManagement from "../components/chase/ChaseManagement";
import MessageManagement from "../components/chat/MessageManagement";
import SimulationManagement from "../components/simulator/SimulationManagement";
import StompConnection from "../components/stompconnection/StompConnection";
import TestManagement from "../components/test/TestManagement";
import POIManagement from "../components/poi/PoiManagement";

const AdminRoutes = () => {
    const [current, setCurrent] = useState("chat");
    const location = useLocation()
    useEffect(() => {
        const path = location.pathname.split("/")[1]
        if(location){
            // console.log(location.pathname)
            setCurrent(path)
            // setCurrent(location.pathname)
        }
    }, [location]);
    return (
        <>
            <AdminNavBar current={current} setCurrent={setCurrent}/>
            <Routes>
                <Route path="/" element={<AdminMain/>}/>

                {/*<Route path="/chat" element={<AdminChat/>}/>*/}
                <Route path="/messageManagement" element={<MessageManagement/>}/>
                <Route path="/chatManagement" element={<ChatManagement/>}/>

                <Route path="/chase" element={<ChaseManagement/>}/>
                <Route path="/devices" element={<DeviceManagement/>}/>
                <Route path="/simulations" element={<SimulationManagement/>}/>
                <Route path="/stompConnection" element={<StompConnection/>}/>
                <Route path="/test" element={<TestManagement/>}/>
                <Route path="/poi" element={<POIManagement/>}/>
            </Routes>
        </>
    )
}
export default AdminRoutes