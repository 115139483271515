// src/services/messageService.ts

import axios from 'axios';
import {CreateMessage, Message} from "../types/Message";
import {Chat} from "../types/Chat";

const API_URL = '/API/messages';

export const saveMessage = async (message: CreateMessage): Promise<Message> => {
    const response = await axios.post(`${API_URL}`, message);
    return response.data;
};

export const getMessages = async (): Promise<Message[]> => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const updateMessage = async (id: string, message: CreateMessage): Promise<Message> => {
    const response = await axios.put(`${API_URL}/update`, {id: id, ...message});
    return response.data;
};

export const deleteMessage = async (id: string)=> {
    await axios.delete(`${API_URL}/${id}`);
}
export const simulateNewMessage = async (message: Message) => {
    const response = await axios.post(`${API_URL}/simulate-new-message`, message)
    return response
}

export const simulateNewMessages = async (quantity: number, fromBD: boolean, nPerMinute: number) => {
    const response  = axios.post(`${API_URL}/simulate-new-messages`,
        { quantity: quantity, fromDB: fromBD, nPerMinute: nPerMinute })
    return response
}
