import {ServerType} from "../../types/StompConnection";
import {Button, Divider, Form, InputNumber, Select, Row, Col} from "antd";
import React, {useState} from "react";

const {Option} = Select;

interface SimulateConnectionsProps {
    buttonTitle?: string,
    initialValues?: any,
    onSimulate: (numberOfConnections: number, type: ServerType, durationInSeconds: number) => void;
}

const SimulateStompConnections: React.FC<SimulateConnectionsProps> = ({onSimulate, buttonTitle, initialValues}) => {
    const [numberOfConnections, setNumberOfConnections] = useState<number>(initialValues?.numberOfConnections ?? 10);
    const [type, setType] = useState<ServerType>(initialValues?.serverType ?? ServerType.CHAT);
    const [durationInSeconds, setDurationInSeconds] = useState<number>(initialValues?.durationInSeconds ?? 60);
    const handleSimulate = () => {
        onSimulate(numberOfConnections, type, durationInSeconds);
    };

    return (
        <>
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                <Form
                    layout="vertical"
                    style={{width: "100%", maxWidth: "1000px", marginInline: 50}}
                    initialValues={{numberOfConnections: 1, type: ServerType.CHAT, durationInSeconds: 60}}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="Numero di connessioni"
                                rules={[{required: true, message: 'Inserire il numero di connessioni!'}]}
                            >
                                <InputNumber
                                    min={1}
                                    value={numberOfConnections}
                                    onChange={(value) => setNumberOfConnections(value as number)}
                                    style={{width: '100%'}}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="Server"
                                rules={[{required: true, message: 'Inserire il server!'}]}
                            >
                                <Select
                                    value={type}
                                    onChange={(serverType) => setType(serverType)}
                                    style={{width: '100%'}}
                                >
                                    <Option value={ServerType.CHAT}>Chat</Option>
                                    <Option value={ServerType.PROXY}>Proxy</Option>
                                    <Option value={ServerType.BOTH}>Entrambi</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="Durata (secondi)"
                                name="durationInSeconds"
                                rules={[{required: true, message: 'Inserire la durata!'}]}
                            >
                                <InputNumber
                                    min={1}
                                    value={durationInSeconds}
                                    onChange={(value) => setDurationInSeconds(value as number)}
                                    style={{width: '100%'}}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Form.Item>
                                <Button type="primary" onClick={handleSimulate} style={{width: '100%'}}>
                                    {buttonTitle ? buttonTitle : "Simula"}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default SimulateStompConnections;
