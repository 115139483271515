// src/components/ChatManager.tsx

import React, {useEffect, useState} from 'react';
import {
    Form,
    Input,
    Button,
    Select,
    Table,
    message as antdMessage,
    Tag,
    Tooltip,
    Row,
    Col,
    TableColumnsType
} from 'antd';
import {CreateChat, Chat, ChatType} from '../../types/Chat';
import {saveChat, getChats, updateChat, deleteChat, simulateNewChat, simulateNewChats} from '../../service/chatService';
import {IoPencilOutline, IoPlayOutline, IoTrashOutline} from "react-icons/io5";
import SimulateChats from "./SimulateChats";

const {Option} = Select;

const ChatManagement: React.FC = () => {
    const [form] = Form.useForm();
    const [chatsLocal, setChatsLocal] = useState<Chat[]>([]);
    const [loading, setLoading] = useState(false);
    const [editingChat, setEditingChat] = useState<Chat | null>(null);
    const [members, setMembers] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const fetchChats = async () => {
        setLoading(true);
        try {
            const data = await getChats();
            setChatsLocal(data);
        } catch (error) {
            antdMessage.error('Failed to load chats.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchChats()
            .catch(error =>
                antdMessage.error(`Errore caricamento chat: ${error}`));
    }, []);

    const onFinish = async (values: CreateChat) => {
        console.log(values)
        const chatValues = {...values, members};
        try {
            if (editingChat) {
                await updateChat({...chatValues, id: editingChat.id});
                antdMessage.success('Chat modificata con successo!');
            } else {
                await saveChat(chatValues);
                antdMessage.success('Chat salvata con successo!');
            }
            form.resetFields();
            setMembers([]);
            setMembers(["1", "2"])
            fetchChats()
                .catch(error =>
                    antdMessage.error(`Errore caricamento chat: ${error}`));
            setEditingChat(null);
        } catch (error) {
            antdMessage.error('Errore salvataggio della chat.');
        }
    };

    const onEdit = (chat: Chat) => {
        form.setFieldsValue(chat);
        setMembers(chat.members);
        setEditingChat(chat);
    };

    const onDelete = async (id: string) => {
        try {
            await deleteChat(id);
            antdMessage.success('Chat eliminata con successo!');
            fetchChats()
                .catch(error =>
                    antdMessage.error(`Errore caricamento chat: ${error}`));
        } catch (error) {
            antdMessage.error('Errore durante la cancellazione della chat.');
        }
    };
    const onSimulate = async (chat: Chat) => {
        try {
            await simulateNewChat(chat);
            antdMessage.success("Chat creata con successo!")
        } catch (error) {
            antdMessage.error("Errore durante la simulazione di creazione della chat.")
        }
    }
    const handleSimulate = async (quantity: number, fromDB: boolean, nPerMinute: number) => {
        setLoading(true);
        try {
            const response = await simulateNewChats(quantity, fromDB, nPerMinute)

            if (response.status === 200) {
                antdMessage.success("Creazione delle chat avvenuta con successo")
            } else {
                antdMessage.error("Errore durante la simulazione di creazione delle chat");
            }
        } catch (error) {
            antdMessage.error(`Errore durante la simulazione di creazione delle chat: ${error}`);
        }
        setLoading(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && !members.includes(inputValue)) {
            setMembers([...members, inputValue]);
        }
        setInputValue('');
    };

    const handleRemoveMember = (member: string) => {
        setMembers(members.filter(m => m !== member));
    };

    const columns: TableColumnsType<Chat> = [
        {
            title: 'Titolo chat',
            dataIndex: 'title',
            key: 'title',
            fixed: 'left',
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Informazioni',
            dataIndex: 'info',
            key: 'info',
        },
        {
            title: 'Autore',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: 'Membri',
            dataIndex: 'members',
            key: 'members',
            render: (members: string[]) => members.join(', '),
        },
        {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            width: 180,
            render: (_: any, record: Chat) => (
                <div style={{flexDirection: "row", height: 20, justifyItems: "center", justifyContent: "center"}}>
                    <Tooltip title="Modifica la chat">
                        <Button style={{marginRight: 8}} icon={<IoPencilOutline/>} type="primary"
                                onClick={() => onEdit(record)}></Button>
                    </Tooltip>
                    <Tooltip title="Simula creazione della chat">
                        <Button style={{marginRight: 8}} icon={<IoPlayOutline/>} type="primary"
                                onClick={() => onSimulate(record)}></Button>
                    </Tooltip>
                    <Tooltip title="Elimina la chat">
                        <Button style={{marginLeft: 20, marginRight: 8}} icon={<IoTrashOutline/>} danger type="primary"
                                onClick={() => onDelete(record.id!)}></Button>
                    </Tooltip>
                </div>
            ),
        },
    ];
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Previene il submit del form
            handleInputConfirm();
        }
    };

    useEffect(() => {
        setMembers(["1", "2"])
    }, []);
    return (
        <>
            <h2 style={{width: "100%", textAlign: "center"}}>Gestione delle chat</h2>
            <div style={{display: "flex", width: "100%", justifyContent: "center", justifyItems: "center"}}>
                <Form
                    initialValues={{
                        group: `true`,
                        type: ChatType.CHAT,
                        title: `Chat${(Math.random() * 1000).toFixed(0)}`,
                        author: "1",
                        info: `info${(Math.random() * 1000).toFixed(0)}`,
                        image: "",
                        members: ["1", "2"]
                    }}
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    style={{
                        justifyContent: "center",
                        justifyItems: "center",
                        justifySelf: "center",
                        marginLeft: 50,
                        marginRight: 50
                    }}>
                    <Row gutter={[40, 0]}>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="group"
                                label="Chat di grouppo?"
                                rules={[{required: true, message: 'Selezionare se è di gruppo!'}]}
                            >
                                <Select>
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="type"
                                label="Tipo di Chat"
                                rules={[{required: true, message: 'Selezionare il tipo di chat!'}]}
                            >
                                <Select>
                                    <Option value={ChatType.CHAT}>Chat</Option>
                                    <Option value={ChatType.CHASE}>Chase</Option>
                                    <Option value={ChatType.FLEET}>Fleet</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item name="title" label="Titolo">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="author"
                                label="Autore"
                                rules={[{required: true, message: 'Inserire l\'autore!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item name="info" label="Info">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item name="image" label="Immagine">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Membri">
                                <div>
                                    {members.map((member) => (
                                        <Tag
                                            key={member}
                                            closable
                                            onClose={() => handleRemoveMember(member)}
                                        >
                                            {member}
                                        </Tag>
                                    ))}
                                    <Input
                                        type="text"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        // onPressEnter={handleInputConfirm}
                                        onKeyDown={handleKeyDown}
                                        placeholder="Inserire un membro e premere Invio"
                                    />
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {editingChat ? 'Modifica chat' : 'Salva chat'}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>

            <SimulateChats onSimulate={handleSimulate}/>

            <Table
                locale={{emptyText: "Non ci sono chat"}}
                style={{minWidth: 700}}
                columns={columns}
                dataSource={chatsLocal}
                scroll={{x: 500}}
                rowKey="id"
                loading={loading}/>

            {/*<Table columns={columns} dataSource={chats} rowKey="id" loading={loading}/>*/}
        </>
    );
};

export default ChatManagement;
