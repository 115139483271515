import React from 'react';
import './App.css';
import AdminRoutes from "./routes/AdminRoutes";
import {BrowserRouter} from "react-router-dom";
import {UserProvider} from "./components/contexts/UsersContext";
import {ChatsProvider} from "./components/contexts/ChatsContext";

function App() {
    return (
        <BrowserRouter>
            <UserProvider>
                <ChatsProvider>
                    <AdminRoutes/>
                </ChatsProvider>
            </UserProvider>
        </BrowserRouter>
    );
}

export default App;
