import React, {useState, useEffect} from 'react';
import {Form, Input, Button, Table, message, Modal, TableColumnsType, Tooltip, Col, Row} from 'antd';
import MapSelector from './MapSelector';
import {SimulationConfig} from "../../types/SimulationConfig";
import {
    createSimulation,
    deleteSimulation,
    getAllSimulations,
    startSimulation,
    updateSimulation
} from "../../service/simulationService";
import {url} from "../../service/ApiConfig";
import {IoPencilOutline, IoPlayOutline, IoTrashOutline} from "react-icons/io5";

const SimulationManagement: React.FC = () => {
    const [form] = Form.useForm();
    // const [ws, setWs] = useState<WebSocket | null>(null);
    const [configurations, setConfigurations] = useState<SimulationConfig[]>([]);
    const [editingConfig, setEditingConfig] = useState<SimulationConfig | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [isMapModalVisible, setIsMapModalVisible] = useState<boolean>(false);

    useEffect(() => {
        const websocket = new WebSocket(`ws://${url}:8080/geo/trackingPush/1_675278774`);

        websocket.onopen = () => {
            console.log('WebSocket connected');
        };

        websocket.onmessage = (event) => {
            console.log('Received:', event.data);
        };

        websocket.onclose = () => {
            console.log('WebSocket disconnected');
        };

        websocket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        // setWs(websocket);

        return () => {
            websocket.close();
        };
    }, []);

    useEffect(() => {
        const fetchConfigurations = async () => {
            try {
                const data = await getAllSimulations();
                setConfigurations(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching configurations:', error);
                setLoading(false);
            }
        };

        fetchConfigurations()
            .catch(error =>
                message.error(`Errore caricamento configurazioni: ${error}`));
    }, []);

    const handleSubmit = async (values: SimulationConfig) => {
        if (editingConfig) {
            try {
                const updatedConfig = await updateSimulation(editingConfig.id!, values);
                setConfigurations(configurations.map(config => config.id === editingConfig.id ? updatedConfig : config));
                message.success('Configuration updated successfully');
                setEditingConfig(null);
            } catch (error) {
                console.error('Error updating configuration:', error);
                message.error('Error updating configuration');
            }
        } else {
            try {
                const newConfig = await createSimulation(values);
                setConfigurations([...configurations, newConfig]);
                message.success('Configuration created successfully');
            } catch (error) {
                console.error('Error creating configuration:', error);
                message.error('Error creating configuration');
            }
        }
        form.resetFields();
    };

    const handleEdit = (record: SimulationConfig) => {
        setEditingConfig(record);
        form.setFieldsValue(record);
    };

    const handleDelete = async (id: string) => {
        try {
            await deleteSimulation(id);
            setConfigurations(configurations.filter(config => config.id !== id));
            message.success('Configuration deleted successfully');
        } catch (error) {
            console.error('Error deleting configuration:', error);
            message.error('Error deleting configuration');
        }
    };

    const handleStartSimulation = async (record: SimulationConfig) => {
        const {deviceLiid, startLat, startLng, endLat, endLng, speed} = record;
        try {
            const res = await startSimulation(deviceLiid, startLat, startLng, endLat, endLng, speed);
            if (res.status === 200) {
                message.success('Simulation started');
            } else if (res.status === 400) {
                message.error(`Error: ${res.data}`);
            }
        } catch (error) {
            console.error('Error starting route simulation:', error);
            message.error(`Error starting simulation: ${error}`);
        }
    };

    const handleMapSelect = (start: { lat: number, lng: number }, end: { lat: number, lng: number }) => {
        form.setFieldsValue({
            startLat: start.lat.toFixed(6),
            startLng: start.lng.toFixed(6),
            endLat: end.lat.toFixed(6),
            endLng: end.lng.toFixed(6),
        });
        setIsMapModalVisible(false);
    };

    const columns: TableColumnsType<SimulationConfig> = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        {
            title: 'Nome',
            dataIndex: 'title',
            key: 'title',
            fixed: 'left',
            // width: 100,
        },
        {
            title: 'Liid del dispositivo',
            dataIndex: 'deviceLiid',
            key: 'deviceLiid',
            fixed: 'left',
            // width: 100,
        },
        {
            title: 'Latitudine di partenza',
            dataIndex: 'startLat',
            key: 'startLat',
        },
        {
            title: 'Longitudine di partenza',
            dataIndex: 'startLng',
            key: 'startLng',
        },
        {
            title: 'Latitudine di arrivo',
            dataIndex: 'endLat',
            key: 'endLat',
        },
        {
            title: 'Longitudine di arrivo',
            dataIndex: 'endLng',
            key: 'endLng',
        },
        {
            title: 'Velocità',
            dataIndex: 'speed',
            key: 'speed',
        },
        {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            width: 180,
            render: (_: any, record: SimulationConfig) => (
                <div style={{flexDirection: "row", height: 20, justifyItems: "center", justifyContent: "center"}}>
                    <Tooltip title="Modifica">
                        <Button style={{marginRight: 8}} icon={<IoPencilOutline/>} type="primary"
                                onClick={() => handleEdit(record)}></Button>
                    </Tooltip>
                    <Tooltip title="Inizia simulazione">

                        <Button style={{marginRight: 8}} icon={<IoPlayOutline/>} type="primary"
                                onClick={() => handleStartSimulation(record)}></Button>
                    </Tooltip>
                    <Tooltip title="Elimina simulazione">
                        <Button style={{marginLeft: 20, marginRight: 8}} icon={<IoTrashOutline/>} danger type="primary"
                                onClick={() => handleDelete(record.id!)}></Button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <div>
            <h2 style={{width: "100%", textAlign: "center"}}>Simulatore di spostamenti</h2>
            <h5 style={{width: "100%", textAlign: "center"}}>Attenzione! La simulazione funziona solo se il server
                "Chase" è collegato al server "Test"</h5>
            <div style={{display: "flex", marginLeft: 50, marginRight: 50, justifyContent: "center"}}>
                <Form
                    // style={{width: 500}}
                    form={form}
                    layout="vertical"
                    initialValues={{
                        title: `Simulation${(Math.random() * 1000).toFixed(0)}`,
                        deviceLiid: '1000000',
                        startLat: `45.06${((Math.random() * 9000) + 1000).toFixed(0)}`,
                        startLng: `7.67${((Math.random() * 9000) + 1000).toFixed(0)}`,
                        endLat: `45.07${((Math.random() * 9000) + 1000).toFixed(0)}`,
                        endLng: `7.65${((Math.random() * 9000) + 1000).toFixed(0)}`,
                        speed: '60',
                    }}
                    onFinish={handleSubmit}
                >
                    <Row gutter={[50, 0]} justify="center">
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="title"
                                label="Nome della simulazione"
                                rules={[{required: true, message: 'Inserire il nome della simulazione!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="deviceLiid"
                                label="Liid del dispositivo"
                                rules={[{required: true, message: 'Inserire il Liid del dispositivo!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="startLat"
                                label="Latitudine di partenza"
                                rules={[{required: true, message: 'Inserire la latitudine del punto di partenza!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="startLng"
                                label="Longitudine di partenza"
                                rules={[{required: true, message: 'Inserire la longitudine del punto di partenza!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="endLat"
                                label="Latitudine di arrivo"
                                rules={[{required: true, message: 'Inserire la latitudine del punto di destinazione!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="endLng"
                                label="Longitudine di arrivo"
                                rules={[{
                                    required: true,
                                    message: 'Inserire la longitudine del punto di destinazione!'
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                name="speed"
                                label="Velocità (km/h)"
                                rules={[{required: true, message: 'Inserire la velocità!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button type="primary" htmlType="submit">
                                {editingConfig ? 'Aggiorna configurazione' : 'Salva simulazione'}
                            </Button>
                            <Button type="default" onClick={() => setIsMapModalVisible(true)}
                                    style={{marginLeft: '10px'}}>
                                Seleziona i punti sulla mappa
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
            <Table columns={columns} dataSource={configurations} scroll={{x: 650}} rowKey="id" loading={loading}/>
            <Modal
                title="Seleziona i punti di partenza e di arrivo"
                open={isMapModalVisible}
                footer={null}
                width={850}
                style={{top: 20, padding: 0}}  // Imposta la posizione del modale
                onCancel={() => setIsMapModalVisible(false)}
            >
                <MapSelector onSelect={handleMapSelect}/>
            </Modal>
        </div>
    );
};

export default SimulationManagement;
