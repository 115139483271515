// src/types.ts

export interface FileDTO {
    url: string;
    name: string;
}

export enum MessageType {
    MESSAGE = "MESSAGE",
    REMOVE = "REMOVE",
    LEAVE = "LEAVE",
    JOIN = "JOIN",
    ADD = "ADD",
}

export interface CreateMessage {
    chatId: string;
    type?: MessageType;
    sender: string;
    content: string;
    attachment?: FileDTO;
    responseFor?: string;
}

export interface Message extends CreateMessage {
    id: string;
    date: string;
}
