import ChatManagement from "./chat/ChatManagement";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const AdminMain = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate("/test")
    }, []);
    return(
        <></>
    )
}
export default AdminMain