import axios, {AxiosResponse} from 'axios';
import {CreateChat, Chat} from '../types/Chat';

const API_URL = '/API/chats';

export const saveChat = async (chat: CreateChat): Promise<Chat> => {
    const response = await axios.post(API_URL, chat);
    return response.data;
};

export const getChats = async (): Promise<Chat[]> => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const updateChat = async (chat: Chat): Promise<Chat> => {
    const response = await axios.put(API_URL, chat);
    return response.data;
};

export const deleteChat = async (id: string): Promise<void> => {
    await axios.delete(`${API_URL}/${id}`);
};

export const simulateNewChat = async (chat: Chat) => {
    const response = await axios.post(`${API_URL}/simulate-new-chat`, chat)
    return response
}

export const simulateNewChats = async (quantity: number, fromBD: boolean, nPerMinute: number) => {
    const response  = axios.post(`${API_URL}/simulate-new-chats`,
        { quantity: quantity, fromDB: fromBD, nPerMinute: nPerMinute })
    return response
}