import {Device} from "../types/Device";
import axios from "axios";

const API_URL = '/API/devices';

export const saveDevice = async (device: Device): Promise<Device> => {
    const response = await axios.post(API_URL, device);
    return response.data;
};

export const getDevices = async (): Promise<Device[]> => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const updateDevice = async (device: Device): Promise<Device> => {
    const response = await axios.put(API_URL, device);
    return response.data;
};

export const deleteDevice = async (id: string): Promise<void> => {
    await axios.delete(`${API_URL}/${id}`);
};
